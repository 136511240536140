// 1. ABSTRACTS
@import 'abstracts/fonts';
@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

// 2. VENDORS
@import 'vendors';

// 3. VENDORS EXTENSIONS
@import 'vendors-extensions/cookiebot';

// 4. BASE
@import 'base/typography';
@import 'base/base';

// 5. LAYOUT
@import 'layout/general';
@import 'layout/header';
@import 'layout/footer';

// 6. COMPONENTS

// 7. PAGES
@import 'pages/index';
