.above-fold {
  position: relative;
  width: 100%;
  background-color: $color-white;
  overflow: hidden;
  margin-bottom: 8em;
  height: calc(100vh + (((100vw * 2165 / 1000) - 100vh) /2 ));

  @media #{$breakpoint-md-up} {
    height: calc(100vh + (((100vw * 1920 / 2880) - 100vh) /2 ));
  }

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    top: 60%;
    z-index: -1;
    background-color: $color-brown;
  }

  &__logo {
    position: absolute;
    width: 100%;
    min-height: 100vh;
    z-index: 2;

    img {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 1.9vw;
      height: calc(100vw* 2165 / 1000);

      @media #{$breakpoint-md-up} {
        margin-top: 3.65vw;
        height: calc(100vw* 1920 / 2880);
      }
    }
  }
  &__bg {
    position: absolute;
    inset: 0;
    background-color: #023DE7;

    img {
      width: 100%;
      height: auto;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__text {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    z-index: 2;

    &-title {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
      @extend .h4-text-style;
      color: $color-violet;
      margin-top: 27vh;

      @media #{$breakpoint-md-up} {
        margin-top: 0;
      }

      span {
        opacity: 0;
        animation: 3s ease forwards 1 fadein;

        @media #{$breakpoint-md-up} {
          margin-bottom: -0.1em;
        }

        &:nth-child(1) {
          animation-delay: 0s;

          @media #{$breakpoint-md-up} {
            padding-bottom: 0.75em;
          }

          @media #{$breakpoint-md-up} and (min-aspect-ratio: 18.5/9) {
            padding-bottom: 0.5em;
          }
        }
        &:nth-child(2) {
          animation-delay: 0.4s;

          @media #{$breakpoint-md-up} {
            padding-bottom: 0.75em;
            margin-bottom: calc(17vw/2);
          }
          @media #{$breakpoint-md-up} and (min-aspect-ratio: 16.5/9) {
            // margin-bottom: 24vh;
          }

          @media #{$breakpoint-md-up} and (min-aspect-ratio: 18.5/9) {
            padding-bottom: 0.5em;
          }
        }
        &:nth-child(3) {
          animation-delay: 0.8s;

          @media #{$breakpoint-md-up} {
            padding-top: 0.75em;
            margin-top: calc(17vw/2);
          }

          @media #{$breakpoint-md-up} and (min-aspect-ratio: 18.5/9) {
            padding-top: 0.5em;
          }
        }
        &:nth-child(4) {
          animation-delay: 1.2s;

          @media #{$breakpoint-md-up} {
            padding-top: 0.75em;
          }

          @media #{$breakpoint-md-up} and (min-aspect-ratio: 18.5/9) {
            padding-top: 0.5em;
          }
        }
      }
    }
  }
}


@keyframes fadein{
  0% { opacity:0; }
  100% { opacity:1; }
}


.about {
  margin-bottom: 6rem;

  @media #{$breakpoint-md-up} {
    margin-bottom: 2rem;
  }

  h1, h2, h3, h4, h5, h6,
  .h1-text-style, .h2-text-style, .h3-text-style, .h4-text-style, .h5-text-style, .h6-text-style {
    color: $color-orange;
  }

  .color-text-hover-orange ,
  .color-text-hover-green,
  .color-text-hover-green-lime,
  .color-text-hover-blue,
  .color-text-hover-violet {
    color: $color-orange;
    text-decoration-color: $color-orange;
  }

  &__divider {
    margin-bottom: 3rem;
  }

  &__intro {
    text-align: center;

    p {
      @extend .body-text--variant;
    }

    &-title {
      margin-bottom: 0.8em;

      @media #{$breakpoint-md-up} {
        margin-bottom: 0.5em;
      }
    }

    &-text {
      margin-bottom: 2em;
    }
  }

  &__follow-section {
    margin-top: 1rem;
    background-color: $color-orange;

    @media #{$breakpoint-md-up} {
      padding: 0.4em 1em;
      margin-top: 1rem;
      margin-left: 50%;
    }
  }

  &__follow-section-btns {
    @media #{$breakpoint-md-up} {
      margin-top: -0.1em;
    }
  }


  &__social-icon {
    width: 4.3rem;
    display: inline-block;
    padding: 0.25rem;
    transition: transform 0.5s ease;

    @media #{$breakpoint-md-up} {
      width: 3rem;
      padding-top: 0;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}

.article {
  margin-top: 1rem;
  margin-bottom: 6rem;

  @media #{$breakpoint-md-up} {
    margin-top: 0;
  }

  h1, h2, h3, h4, h5, h6,
  .h1-text-style, .h2-text-style, .h3-text-style, .h4-text-style, .h5-text-style, .h6-text-style {
    color: $color-green;
  }

  &__divider {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  &__img {
    img {
      width: 100%;
      border-radius: 0.5rem;
    }
  }

  &__pause {
    margin: 3rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 5em;
  }

  &__story-heading {
    padding-top: 3rem;
    margin-bottom: 3rem;
    text-align: center;

    @media #{$breakpoint-md-up} {
      text-align: left;
    }

    &-details {
      margin-top: 4em;
      margin-bottom: 0.25em;

      @media #{$breakpoint-md-up} {
        margin-top: 0;
      }

      p + p {
        padding-top: 0.8em;
      }

      p {
        @extend .body-text--variant;
      }
    }
  }

  .btn {
    margin-left: 40%;
    margin-top: 1em;

    span:after {
      content: 'More';
    }
  }

  &.article--open .btn span:after {
    content: 'Less';
  }

  &__story-text {
    position: relative;
    max-height: 105em;
    overflow: hidden;
    height: auto;
    transition: max-height 0s cubic-bezier(0.64, 0, 0.78, 0);

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 5em;
      width: 100%;
      background-image: linear-gradient(to bottom, rgba(61, 47, 36, 0), rgba(35, 17, 0, 0.9) 80%, rgba(35, 17, 0, 1) 100%);
      transition: opacity 0.25s ease 0.2s;
    }

    .article--open & {
      max-height: 1100em;
      transition: max-height 1s cubic-bezier(0.64, 0, 0.78, 0);

      @media #{$breakpoint-md-up} {
        max-height: 660em;
      }

      &:after {
        opacity: 0;
      }
    }

    .col-xs-34 {
      position: relative;
    }
  }
  &__text-group {
    & + & {
      margin-top: 1em;
    }
  }

  &__illustration-1 {
    width: 70vw;
    margin: 2rem auto;

    img {
      width: 100%;
    }

    @media #{$breakpoint-md-up} {
      width: 65%;
      margin-top: 1em;
      margin-right: -40%;
      shape-outside: border-box;
      float: right;
      padding-left: 30px;
      display: block;
      position: relative;
    }
  }

  &__illustration-2 {
    width: 60vw;
    margin: 2rem auto;

    img {
      width: 100%;
    }

    @media #{$breakpoint-md-up} {
      width: 64%;
      margin-top: 0.2em;
      padding-bottom: 1em;
      margin-right: -30%;
      shape-outside: border-box;
      float: right;
      padding-left: 30px;
      display: block;
      position: relative;
    }
  }

  &__illustration-3 {
    width: 80vw;
    margin: 2rem auto;
    margin-top: 1rem;

    img {
      width: 100%;
      @media #{$breakpoint-md-up} {
        margin-left: -3em;
      }
    }

    @media #{$breakpoint-md-up} {
      width: 64%;
      margin-right: -32%;
      margin-top: initial;
      shape-outside: border-box;
      float: right;
      display: block;
      position: relative;
    }
  }

  &__illustration-4 {
    width: 50vw;
    margin: 2rem auto;

    img {
      width: 100%;
    }

    @media #{$breakpoint-md-up} {
      width: 65%;
      margin-top: 2%;
      margin-right: -31%;
      shape-outside: border-box;
      float: right;
      padding-left: 30px;
      padding-top: 1em;
      padding-bottom: 6em;
      display: block;
      position: relative;
    }
  }
}

.call {

  @media #{$breakpoint-md-up} {
    padding-top: 2rem;
  }

  h1, h2, h3, h4, h5, h6,
  .h1-text-style, .h2-text-style, .h3-text-style, .h4-text-style, .h5-text-style, .h6-text-style {
    color: $color-blue;
  }

  &__divider {
    margin-bottom: 3rem;
  }

  &__intro {
    margin-bottom: 5rem;
    text-align: center;

    p {
      @extend .body-text--variant;
    }

    &-title {
      margin-bottom: 0.8em;

      @media #{$breakpoint-md-up} {
        margin-bottom: 0.4em;
      }
    }
  }

  &__text-details {
    margin-top: 3em;

    @media #{$breakpoint-md-up} {
      margin-top: 0;
    }
  }

  .btn {
    @media #{$breakpoint-md-up} {
      margin-top: 3rem;
    }
  }
}
