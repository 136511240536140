@font-face {
  font-family: "Alte Haas Grotesk";
  src: url('../fonts/AlteHaasGroteskRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Alte Haas Grotesk";
  src: url('../fonts/AlteHaasGroteskBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
