#CybotCookiebotDialog {
  z-index: 100!important;
}

#CybotCookiebotDialog.CybotEdge {
  padding: 15px !important;
  border-radius: 0;
  z-index: 100!important;

  @media screen and (min-width:1280px){
    padding: 15px;
  }

  * {
    font-size: 0.6rem;
    vertical-align: middle;
  }

  .CybotCookiebotDialogContentWrapper {
    align-items: center;

    #CybotCookiebotDialogBodyContentText a {
      color: inherit;
      border-bottom: 1px solid $color-orange;
    }

    #CybotCookiebotDialogHeader {
      width: auto;
      border: 0;
      padding: 0;
    }

    #CybotCookiebotDialogHeaderLogosWrapper {
      display: none;
    }
    #CybotCookiebotDialogTabContent {
      margin-left: 0;
    }
    #CybotCookiebotDialogBodyButtonsWrapper {
      @media screen and (min-width:1280px){
        flex-direction: row !important;
      }
    }
    .CybotCookiebotDialogBodyBottomWrapper,
    #CybotCookiebotDialogBodyEdgeMoreDetails,
    #CybotCookiebotDialogBodyContentTitle {
      display: none;
    }

    #CybotCookiebotDialogBodyLevelButtonCustomize {
      border-width: 1px;
    }

    #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
      padding: .75em;
      font-weight: normal;
      border-width: 1px;
      letter-spacing: 0.02em;

      .CybotCookiebotDialogArrow {
        display: none;
      }
    }

    .CybotCookiebotScrollContainer {
      min-height: initial;
    }

    #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyButton:not(:last-of-type),
    #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton,
    #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton:not(:first-of-type) {
      @media screen and (min-width: 601px) {
        margin-bottom: 0;
      }
    }

    #CybotCookiebotDialogBody .CybotCookiebotScrollContainer {
      padding: 0;
    }
  }
}

