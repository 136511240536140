html {
  scroll-behavior: smooth;
}

body {
  background-color: $color-brown;
  color: $color-white;
  overflow-x: hidden;
}

img {
  max-inline-size: 100%;
}

ol {
  counter-reset: item;

  li {
    display: block;

    &::before {
      content: counter(item) ") ";
      counter-increment: item;
    }
  }
}

blockquote {
  margin-left: 0;
  margin-right: 0;
  margin-top: 4rem;
  margin-bottom: 4rem;
  text-align: center;

  @media #{$breakpoint-sm-up} {
    text-align: left;
  }
}
