*, :before, :after {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

:before, :after {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

:where(:root) {
  cursor: default;
  overflow-wrap: break-word;
  tab-size: 4;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
}

:where(body) {
  margin: 0;
}

:where(h1) {
  margin: .67em 0;
  font-size: 2em;
}

:where(dl, ol, ul) :where(dl, ol, ul) {
  margin: 0;
}

:where(hr) {
  color: inherit;
  height: 0;
}

:where(nav) :where(ol, ul) {
  padding: 0;
  list-style-type: none;
}

:where(nav li):before {
  content: "​";
  float: left;
}

:where(pre) {
  font-family: monospace;
  font-size: 1em;
  overflow: auto;
}

:where(abbr[title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

:where(b, strong) {
  font-weight: bolder;
}

:where(code, kbd, samp) {
  font-family: monospace;
  font-size: 1em;
}

:where(small) {
  font-size: 80%;
}

:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

:where(iframe) {
  border-style: none;
}

:where(svg:not([fill])) {
  fill: currentColor;
}

:where(table) {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
}

:where(button, input, select) {
  margin: 0;
}

:where(button, [type="button" i], [type="reset" i], [type="submit" i]) {
  -webkit-appearance: button;
}

:where(fieldset) {
  border: 1px solid #a0a0a0;
}

:where(progress) {
  vertical-align: baseline;
}

:where(textarea) {
  resize: vertical;
  margin: 0;
}

:where([type="search" i]) {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

:where(dialog) {
  color: #000;
  height: -moz-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #fff;
  border: solid;
  margin: auto;
  padding: 1em;
  position: absolute;
  left: 0;
  right: 0;
}

:where(dialog:not([open])) {
  display: none;
}

:where(details > summary:first-of-type) {
  display: list-item;
}

:where([aria-busy="true" i]) {
  cursor: progress;
}

:where([aria-controls]) {
  cursor: pointer;
}

:where([aria-disabled="true" i], [disabled]) {
  cursor: not-allowed;
}

:where([aria-hidden="false" i][hidden]) {
  display: initial;
}

:where([aria-hidden="false" i][hidden]:not(:focus)) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

@font-face {
  font-family: Alte Haas Grotesk;
  src: url("AlteHaasGroteskRegular.c6bfe676.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Alte Haas Grotesk;
  src: url("AlteHaasGroteskBold.6012b8c2.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

.row {
  box-sizing: border-box;
  width: 100%;
  flex-flow: wrap;
  flex: 0 auto;
  margin: 0 auto;
  display: flex;
}

.row.reverse {
  flex-direction: row-reverse;
}

.row.natural-height {
  align-items: flex-start;
}

.row.flex-column {
  flex-direction: column;
}

.col {
  box-sizing: border-box;
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0;
}

.col.reverse {
  flex-direction: column-reverse;
}

.first {
  order: -1;
}

.last {
  order: 1;
}

.align-start {
  align-self: flex-start;
}

.align-end {
  align-self: flex-end;
}

.align-center {
  align-self: center;
}

.align-baseline {
  align-self: baseline;
}

.align-stretch {
  align-self: stretch;
}

.col-xs {
  box-sizing: border-box;
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0;
}

.col-xs-1 {
  box-sizing: border-box;
  max-width: 2.5%;
  flex-basis: 2.5%;
  padding: 0;
}

.col-xs-offset-1 {
  margin-left: 2.5%;
}

.col-xs-2 {
  box-sizing: border-box;
  max-width: 5%;
  flex-basis: 5%;
  padding: 0;
}

.col-xs-offset-2 {
  margin-left: 5%;
}

.col-xs-3 {
  box-sizing: border-box;
  max-width: 7.5%;
  flex-basis: 7.5%;
  padding: 0;
}

.col-xs-offset-3 {
  margin-left: 7.5%;
}

.col-xs-4 {
  box-sizing: border-box;
  max-width: 10%;
  flex-basis: 10%;
  padding: 0;
}

.col-xs-offset-4 {
  margin-left: 10%;
}

.col-xs-5 {
  box-sizing: border-box;
  max-width: 12.5%;
  flex-basis: 12.5%;
  padding: 0;
}

.col-xs-offset-5 {
  margin-left: 12.5%;
}

.col-xs-6 {
  box-sizing: border-box;
  max-width: 15%;
  flex-basis: 15%;
  padding: 0;
}

.col-xs-offset-6 {
  margin-left: 15%;
}

.col-xs-7 {
  box-sizing: border-box;
  max-width: 17.5%;
  flex-basis: 17.5%;
  padding: 0;
}

.col-xs-offset-7 {
  margin-left: 17.5%;
}

.col-xs-8 {
  box-sizing: border-box;
  max-width: 20%;
  flex-basis: 20%;
  padding: 0;
}

.col-xs-offset-8 {
  margin-left: 20%;
}

.col-xs-9 {
  box-sizing: border-box;
  max-width: 22.5%;
  flex-basis: 22.5%;
  padding: 0;
}

.col-xs-offset-9 {
  margin-left: 22.5%;
}

.col-xs-10 {
  box-sizing: border-box;
  max-width: 25%;
  flex-basis: 25%;
  padding: 0;
}

.col-xs-offset-10 {
  margin-left: 25%;
}

.col-xs-11 {
  box-sizing: border-box;
  max-width: 27.5%;
  flex-basis: 27.5%;
  padding: 0;
}

.col-xs-offset-11 {
  margin-left: 27.5%;
}

.col-xs-12 {
  box-sizing: border-box;
  max-width: 30%;
  flex-basis: 30%;
  padding: 0;
}

.col-xs-offset-12 {
  margin-left: 30%;
}

.col-xs-13 {
  box-sizing: border-box;
  max-width: 32.5%;
  flex-basis: 32.5%;
  padding: 0;
}

.col-xs-offset-13 {
  margin-left: 32.5%;
}

.col-xs-14 {
  box-sizing: border-box;
  max-width: 35%;
  flex-basis: 35%;
  padding: 0;
}

.col-xs-offset-14 {
  margin-left: 35%;
}

.col-xs-15 {
  box-sizing: border-box;
  max-width: 37.5%;
  flex-basis: 37.5%;
  padding: 0;
}

.col-xs-offset-15 {
  margin-left: 37.5%;
}

.col-xs-16 {
  box-sizing: border-box;
  max-width: 40%;
  flex-basis: 40%;
  padding: 0;
}

.col-xs-offset-16 {
  margin-left: 40%;
}

.col-xs-17 {
  box-sizing: border-box;
  max-width: 42.5%;
  flex-basis: 42.5%;
  padding: 0;
}

.col-xs-offset-17 {
  margin-left: 42.5%;
}

.col-xs-18 {
  box-sizing: border-box;
  max-width: 45%;
  flex-basis: 45%;
  padding: 0;
}

.col-xs-offset-18 {
  margin-left: 45%;
}

.col-xs-19 {
  box-sizing: border-box;
  max-width: 47.5%;
  flex-basis: 47.5%;
  padding: 0;
}

.col-xs-offset-19 {
  margin-left: 47.5%;
}

.col-xs-20 {
  box-sizing: border-box;
  max-width: 50%;
  flex-basis: 50%;
  padding: 0;
}

.col-xs-offset-20 {
  margin-left: 50%;
}

.col-xs-21 {
  box-sizing: border-box;
  max-width: 52.5%;
  flex-basis: 52.5%;
  padding: 0;
}

.col-xs-offset-21 {
  margin-left: 52.5%;
}

.col-xs-22 {
  box-sizing: border-box;
  max-width: 55%;
  flex-basis: 55%;
  padding: 0;
}

.col-xs-offset-22 {
  margin-left: 55%;
}

.col-xs-23 {
  box-sizing: border-box;
  max-width: 57.5%;
  flex-basis: 57.5%;
  padding: 0;
}

.col-xs-offset-23 {
  margin-left: 57.5%;
}

.col-xs-24 {
  box-sizing: border-box;
  max-width: 60%;
  flex-basis: 60%;
  padding: 0;
}

.col-xs-offset-24 {
  margin-left: 60%;
}

.col-xs-25 {
  box-sizing: border-box;
  max-width: 62.5%;
  flex-basis: 62.5%;
  padding: 0;
}

.col-xs-offset-25 {
  margin-left: 62.5%;
}

.col-xs-26 {
  box-sizing: border-box;
  max-width: 65%;
  flex-basis: 65%;
  padding: 0;
}

.col-xs-offset-26 {
  margin-left: 65%;
}

.col-xs-27 {
  box-sizing: border-box;
  max-width: 67.5%;
  flex-basis: 67.5%;
  padding: 0;
}

.col-xs-offset-27 {
  margin-left: 67.5%;
}

.col-xs-28 {
  box-sizing: border-box;
  max-width: 70%;
  flex-basis: 70%;
  padding: 0;
}

.col-xs-offset-28 {
  margin-left: 70%;
}

.col-xs-29 {
  box-sizing: border-box;
  max-width: 72.5%;
  flex-basis: 72.5%;
  padding: 0;
}

.col-xs-offset-29 {
  margin-left: 72.5%;
}

.col-xs-30 {
  box-sizing: border-box;
  max-width: 75%;
  flex-basis: 75%;
  padding: 0;
}

.col-xs-offset-30 {
  margin-left: 75%;
}

.col-xs-31 {
  box-sizing: border-box;
  max-width: 77.5%;
  flex-basis: 77.5%;
  padding: 0;
}

.col-xs-offset-31 {
  margin-left: 77.5%;
}

.col-xs-32 {
  box-sizing: border-box;
  max-width: 80%;
  flex-basis: 80%;
  padding: 0;
}

.col-xs-offset-32 {
  margin-left: 80%;
}

.col-xs-33 {
  box-sizing: border-box;
  max-width: 82.5%;
  flex-basis: 82.5%;
  padding: 0;
}

.col-xs-offset-33 {
  margin-left: 82.5%;
}

.col-xs-34 {
  box-sizing: border-box;
  max-width: 85%;
  flex-basis: 85%;
  padding: 0;
}

.col-xs-offset-34 {
  margin-left: 85%;
}

.col-xs-35 {
  box-sizing: border-box;
  max-width: 87.5%;
  flex-basis: 87.5%;
  padding: 0;
}

.col-xs-offset-35 {
  margin-left: 87.5%;
}

.col-xs-36 {
  box-sizing: border-box;
  max-width: 90%;
  flex-basis: 90%;
  padding: 0;
}

.col-xs-offset-36 {
  margin-left: 90%;
}

.col-xs-37 {
  box-sizing: border-box;
  max-width: 92.5%;
  flex-basis: 92.5%;
  padding: 0;
}

.col-xs-offset-37 {
  margin-left: 92.5%;
}

.col-xs-38 {
  box-sizing: border-box;
  max-width: 95%;
  flex-basis: 95%;
  padding: 0;
}

.col-xs-offset-38 {
  margin-left: 95%;
}

.col-xs-39 {
  box-sizing: border-box;
  max-width: 97.5%;
  flex-basis: 97.5%;
  padding: 0;
}

.col-xs-offset-39 {
  margin-left: 97.5%;
}

.col-xs-40 {
  box-sizing: border-box;
  max-width: 100%;
  flex-basis: 100%;
  padding: 0;
}

.col-xs-offset-40 {
  margin-left: 100%;
}

.row.start-xs {
  justify-content: flex-start;
}

.row.center-xs {
  justify-content: center;
}

.row.end-xs {
  justify-content: flex-end;
}

.row.top-xs {
  align-items: flex-start;
}

.row.middle-xs {
  align-items: center;
}

.row.bottom-xs {
  align-items: flex-end;
}

.row.around-xs {
  justify-content: space-around;
}

.row.between-xs {
  justify-content: space-between;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

@media only screen and (min-width: 641px) {
  .col-sm {
    box-sizing: border-box;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0;
  }

  .col-sm-1 {
    box-sizing: border-box;
    max-width: 2.5%;
    flex-basis: 2.5%;
    padding: 0;
  }

  .col-sm-offset-1 {
    margin-left: 2.5%;
  }

  .col-sm-2 {
    box-sizing: border-box;
    max-width: 5%;
    flex-basis: 5%;
    padding: 0;
  }

  .col-sm-offset-2 {
    margin-left: 5%;
  }

  .col-sm-3 {
    box-sizing: border-box;
    max-width: 7.5%;
    flex-basis: 7.5%;
    padding: 0;
  }

  .col-sm-offset-3 {
    margin-left: 7.5%;
  }

  .col-sm-4 {
    box-sizing: border-box;
    max-width: 10%;
    flex-basis: 10%;
    padding: 0;
  }

  .col-sm-offset-4 {
    margin-left: 10%;
  }

  .col-sm-5 {
    box-sizing: border-box;
    max-width: 12.5%;
    flex-basis: 12.5%;
    padding: 0;
  }

  .col-sm-offset-5 {
    margin-left: 12.5%;
  }

  .col-sm-6 {
    box-sizing: border-box;
    max-width: 15%;
    flex-basis: 15%;
    padding: 0;
  }

  .col-sm-offset-6 {
    margin-left: 15%;
  }

  .col-sm-7 {
    box-sizing: border-box;
    max-width: 17.5%;
    flex-basis: 17.5%;
    padding: 0;
  }

  .col-sm-offset-7 {
    margin-left: 17.5%;
  }

  .col-sm-8 {
    box-sizing: border-box;
    max-width: 20%;
    flex-basis: 20%;
    padding: 0;
  }

  .col-sm-offset-8 {
    margin-left: 20%;
  }

  .col-sm-9 {
    box-sizing: border-box;
    max-width: 22.5%;
    flex-basis: 22.5%;
    padding: 0;
  }

  .col-sm-offset-9 {
    margin-left: 22.5%;
  }

  .col-sm-10 {
    box-sizing: border-box;
    max-width: 25%;
    flex-basis: 25%;
    padding: 0;
  }

  .col-sm-offset-10 {
    margin-left: 25%;
  }

  .col-sm-11 {
    box-sizing: border-box;
    max-width: 27.5%;
    flex-basis: 27.5%;
    padding: 0;
  }

  .col-sm-offset-11 {
    margin-left: 27.5%;
  }

  .col-sm-12 {
    box-sizing: border-box;
    max-width: 30%;
    flex-basis: 30%;
    padding: 0;
  }

  .col-sm-offset-12 {
    margin-left: 30%;
  }

  .col-sm-13 {
    box-sizing: border-box;
    max-width: 32.5%;
    flex-basis: 32.5%;
    padding: 0;
  }

  .col-sm-offset-13 {
    margin-left: 32.5%;
  }

  .col-sm-14 {
    box-sizing: border-box;
    max-width: 35%;
    flex-basis: 35%;
    padding: 0;
  }

  .col-sm-offset-14 {
    margin-left: 35%;
  }

  .col-sm-15 {
    box-sizing: border-box;
    max-width: 37.5%;
    flex-basis: 37.5%;
    padding: 0;
  }

  .col-sm-offset-15 {
    margin-left: 37.5%;
  }

  .col-sm-16 {
    box-sizing: border-box;
    max-width: 40%;
    flex-basis: 40%;
    padding: 0;
  }

  .col-sm-offset-16 {
    margin-left: 40%;
  }

  .col-sm-17 {
    box-sizing: border-box;
    max-width: 42.5%;
    flex-basis: 42.5%;
    padding: 0;
  }

  .col-sm-offset-17 {
    margin-left: 42.5%;
  }

  .col-sm-18 {
    box-sizing: border-box;
    max-width: 45%;
    flex-basis: 45%;
    padding: 0;
  }

  .col-sm-offset-18 {
    margin-left: 45%;
  }

  .col-sm-19 {
    box-sizing: border-box;
    max-width: 47.5%;
    flex-basis: 47.5%;
    padding: 0;
  }

  .col-sm-offset-19 {
    margin-left: 47.5%;
  }

  .col-sm-20 {
    box-sizing: border-box;
    max-width: 50%;
    flex-basis: 50%;
    padding: 0;
  }

  .col-sm-offset-20 {
    margin-left: 50%;
  }

  .col-sm-21 {
    box-sizing: border-box;
    max-width: 52.5%;
    flex-basis: 52.5%;
    padding: 0;
  }

  .col-sm-offset-21 {
    margin-left: 52.5%;
  }

  .col-sm-22 {
    box-sizing: border-box;
    max-width: 55%;
    flex-basis: 55%;
    padding: 0;
  }

  .col-sm-offset-22 {
    margin-left: 55%;
  }

  .col-sm-23 {
    box-sizing: border-box;
    max-width: 57.5%;
    flex-basis: 57.5%;
    padding: 0;
  }

  .col-sm-offset-23 {
    margin-left: 57.5%;
  }

  .col-sm-24 {
    box-sizing: border-box;
    max-width: 60%;
    flex-basis: 60%;
    padding: 0;
  }

  .col-sm-offset-24 {
    margin-left: 60%;
  }

  .col-sm-25 {
    box-sizing: border-box;
    max-width: 62.5%;
    flex-basis: 62.5%;
    padding: 0;
  }

  .col-sm-offset-25 {
    margin-left: 62.5%;
  }

  .col-sm-26 {
    box-sizing: border-box;
    max-width: 65%;
    flex-basis: 65%;
    padding: 0;
  }

  .col-sm-offset-26 {
    margin-left: 65%;
  }

  .col-sm-27 {
    box-sizing: border-box;
    max-width: 67.5%;
    flex-basis: 67.5%;
    padding: 0;
  }

  .col-sm-offset-27 {
    margin-left: 67.5%;
  }

  .col-sm-28 {
    box-sizing: border-box;
    max-width: 70%;
    flex-basis: 70%;
    padding: 0;
  }

  .col-sm-offset-28 {
    margin-left: 70%;
  }

  .col-sm-29 {
    box-sizing: border-box;
    max-width: 72.5%;
    flex-basis: 72.5%;
    padding: 0;
  }

  .col-sm-offset-29 {
    margin-left: 72.5%;
  }

  .col-sm-30 {
    box-sizing: border-box;
    max-width: 75%;
    flex-basis: 75%;
    padding: 0;
  }

  .col-sm-offset-30 {
    margin-left: 75%;
  }

  .col-sm-31 {
    box-sizing: border-box;
    max-width: 77.5%;
    flex-basis: 77.5%;
    padding: 0;
  }

  .col-sm-offset-31 {
    margin-left: 77.5%;
  }

  .col-sm-32 {
    box-sizing: border-box;
    max-width: 80%;
    flex-basis: 80%;
    padding: 0;
  }

  .col-sm-offset-32 {
    margin-left: 80%;
  }

  .col-sm-33 {
    box-sizing: border-box;
    max-width: 82.5%;
    flex-basis: 82.5%;
    padding: 0;
  }

  .col-sm-offset-33 {
    margin-left: 82.5%;
  }

  .col-sm-34 {
    box-sizing: border-box;
    max-width: 85%;
    flex-basis: 85%;
    padding: 0;
  }

  .col-sm-offset-34 {
    margin-left: 85%;
  }

  .col-sm-35 {
    box-sizing: border-box;
    max-width: 87.5%;
    flex-basis: 87.5%;
    padding: 0;
  }

  .col-sm-offset-35 {
    margin-left: 87.5%;
  }

  .col-sm-36 {
    box-sizing: border-box;
    max-width: 90%;
    flex-basis: 90%;
    padding: 0;
  }

  .col-sm-offset-36 {
    margin-left: 90%;
  }

  .col-sm-37 {
    box-sizing: border-box;
    max-width: 92.5%;
    flex-basis: 92.5%;
    padding: 0;
  }

  .col-sm-offset-37 {
    margin-left: 92.5%;
  }

  .col-sm-38 {
    box-sizing: border-box;
    max-width: 95%;
    flex-basis: 95%;
    padding: 0;
  }

  .col-sm-offset-38 {
    margin-left: 95%;
  }

  .col-sm-39 {
    box-sizing: border-box;
    max-width: 97.5%;
    flex-basis: 97.5%;
    padding: 0;
  }

  .col-sm-offset-39 {
    margin-left: 97.5%;
  }

  .col-sm-40 {
    box-sizing: border-box;
    max-width: 100%;
    flex-basis: 100%;
    padding: 0;
  }

  .col-sm-offset-40 {
    margin-left: 100%;
  }

  .row.start-sm {
    justify-content: flex-start;
  }

  .row.center-sm {
    justify-content: center;
  }

  .row.end-sm {
    justify-content: flex-end;
  }

  .row.top-sm {
    align-items: flex-start;
  }

  .row.middle-sm {
    align-items: center;
  }

  .row.bottom-sm {
    align-items: flex-end;
  }

  .row.around-sm {
    justify-content: space-around;
  }

  .row.between-sm {
    justify-content: space-between;
  }

  .first-sm {
    order: -1;
  }

  .last-sm {
    order: 1;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md {
    box-sizing: border-box;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0;
  }

  .col-md-1 {
    box-sizing: border-box;
    max-width: 2.5%;
    flex-basis: 2.5%;
    padding: 0;
  }

  .col-md-offset-1 {
    margin-left: 2.5%;
  }

  .col-md-2 {
    box-sizing: border-box;
    max-width: 5%;
    flex-basis: 5%;
    padding: 0;
  }

  .col-md-offset-2 {
    margin-left: 5%;
  }

  .col-md-3 {
    box-sizing: border-box;
    max-width: 7.5%;
    flex-basis: 7.5%;
    padding: 0;
  }

  .col-md-offset-3 {
    margin-left: 7.5%;
  }

  .col-md-4 {
    box-sizing: border-box;
    max-width: 10%;
    flex-basis: 10%;
    padding: 0;
  }

  .col-md-offset-4 {
    margin-left: 10%;
  }

  .col-md-5 {
    box-sizing: border-box;
    max-width: 12.5%;
    flex-basis: 12.5%;
    padding: 0;
  }

  .col-md-offset-5 {
    margin-left: 12.5%;
  }

  .col-md-6 {
    box-sizing: border-box;
    max-width: 15%;
    flex-basis: 15%;
    padding: 0;
  }

  .col-md-offset-6 {
    margin-left: 15%;
  }

  .col-md-7 {
    box-sizing: border-box;
    max-width: 17.5%;
    flex-basis: 17.5%;
    padding: 0;
  }

  .col-md-offset-7 {
    margin-left: 17.5%;
  }

  .col-md-8 {
    box-sizing: border-box;
    max-width: 20%;
    flex-basis: 20%;
    padding: 0;
  }

  .col-md-offset-8 {
    margin-left: 20%;
  }

  .col-md-9 {
    box-sizing: border-box;
    max-width: 22.5%;
    flex-basis: 22.5%;
    padding: 0;
  }

  .col-md-offset-9 {
    margin-left: 22.5%;
  }

  .col-md-10 {
    box-sizing: border-box;
    max-width: 25%;
    flex-basis: 25%;
    padding: 0;
  }

  .col-md-offset-10 {
    margin-left: 25%;
  }

  .col-md-11 {
    box-sizing: border-box;
    max-width: 27.5%;
    flex-basis: 27.5%;
    padding: 0;
  }

  .col-md-offset-11 {
    margin-left: 27.5%;
  }

  .col-md-12 {
    box-sizing: border-box;
    max-width: 30%;
    flex-basis: 30%;
    padding: 0;
  }

  .col-md-offset-12 {
    margin-left: 30%;
  }

  .col-md-13 {
    box-sizing: border-box;
    max-width: 32.5%;
    flex-basis: 32.5%;
    padding: 0;
  }

  .col-md-offset-13 {
    margin-left: 32.5%;
  }

  .col-md-14 {
    box-sizing: border-box;
    max-width: 35%;
    flex-basis: 35%;
    padding: 0;
  }

  .col-md-offset-14 {
    margin-left: 35%;
  }

  .col-md-15 {
    box-sizing: border-box;
    max-width: 37.5%;
    flex-basis: 37.5%;
    padding: 0;
  }

  .col-md-offset-15 {
    margin-left: 37.5%;
  }

  .col-md-16 {
    box-sizing: border-box;
    max-width: 40%;
    flex-basis: 40%;
    padding: 0;
  }

  .col-md-offset-16 {
    margin-left: 40%;
  }

  .col-md-17 {
    box-sizing: border-box;
    max-width: 42.5%;
    flex-basis: 42.5%;
    padding: 0;
  }

  .col-md-offset-17 {
    margin-left: 42.5%;
  }

  .col-md-18 {
    box-sizing: border-box;
    max-width: 45%;
    flex-basis: 45%;
    padding: 0;
  }

  .col-md-offset-18 {
    margin-left: 45%;
  }

  .col-md-19 {
    box-sizing: border-box;
    max-width: 47.5%;
    flex-basis: 47.5%;
    padding: 0;
  }

  .col-md-offset-19 {
    margin-left: 47.5%;
  }

  .col-md-20 {
    box-sizing: border-box;
    max-width: 50%;
    flex-basis: 50%;
    padding: 0;
  }

  .col-md-offset-20 {
    margin-left: 50%;
  }

  .col-md-21 {
    box-sizing: border-box;
    max-width: 52.5%;
    flex-basis: 52.5%;
    padding: 0;
  }

  .col-md-offset-21 {
    margin-left: 52.5%;
  }

  .col-md-22 {
    box-sizing: border-box;
    max-width: 55%;
    flex-basis: 55%;
    padding: 0;
  }

  .col-md-offset-22 {
    margin-left: 55%;
  }

  .col-md-23 {
    box-sizing: border-box;
    max-width: 57.5%;
    flex-basis: 57.5%;
    padding: 0;
  }

  .col-md-offset-23 {
    margin-left: 57.5%;
  }

  .col-md-24 {
    box-sizing: border-box;
    max-width: 60%;
    flex-basis: 60%;
    padding: 0;
  }

  .col-md-offset-24 {
    margin-left: 60%;
  }

  .col-md-25 {
    box-sizing: border-box;
    max-width: 62.5%;
    flex-basis: 62.5%;
    padding: 0;
  }

  .col-md-offset-25 {
    margin-left: 62.5%;
  }

  .col-md-26 {
    box-sizing: border-box;
    max-width: 65%;
    flex-basis: 65%;
    padding: 0;
  }

  .col-md-offset-26 {
    margin-left: 65%;
  }

  .col-md-27 {
    box-sizing: border-box;
    max-width: 67.5%;
    flex-basis: 67.5%;
    padding: 0;
  }

  .col-md-offset-27 {
    margin-left: 67.5%;
  }

  .col-md-28 {
    box-sizing: border-box;
    max-width: 70%;
    flex-basis: 70%;
    padding: 0;
  }

  .col-md-offset-28 {
    margin-left: 70%;
  }

  .col-md-29 {
    box-sizing: border-box;
    max-width: 72.5%;
    flex-basis: 72.5%;
    padding: 0;
  }

  .col-md-offset-29 {
    margin-left: 72.5%;
  }

  .col-md-30 {
    box-sizing: border-box;
    max-width: 75%;
    flex-basis: 75%;
    padding: 0;
  }

  .col-md-offset-30 {
    margin-left: 75%;
  }

  .col-md-31 {
    box-sizing: border-box;
    max-width: 77.5%;
    flex-basis: 77.5%;
    padding: 0;
  }

  .col-md-offset-31 {
    margin-left: 77.5%;
  }

  .col-md-32 {
    box-sizing: border-box;
    max-width: 80%;
    flex-basis: 80%;
    padding: 0;
  }

  .col-md-offset-32 {
    margin-left: 80%;
  }

  .col-md-33 {
    box-sizing: border-box;
    max-width: 82.5%;
    flex-basis: 82.5%;
    padding: 0;
  }

  .col-md-offset-33 {
    margin-left: 82.5%;
  }

  .col-md-34 {
    box-sizing: border-box;
    max-width: 85%;
    flex-basis: 85%;
    padding: 0;
  }

  .col-md-offset-34 {
    margin-left: 85%;
  }

  .col-md-35 {
    box-sizing: border-box;
    max-width: 87.5%;
    flex-basis: 87.5%;
    padding: 0;
  }

  .col-md-offset-35 {
    margin-left: 87.5%;
  }

  .col-md-36 {
    box-sizing: border-box;
    max-width: 90%;
    flex-basis: 90%;
    padding: 0;
  }

  .col-md-offset-36 {
    margin-left: 90%;
  }

  .col-md-37 {
    box-sizing: border-box;
    max-width: 92.5%;
    flex-basis: 92.5%;
    padding: 0;
  }

  .col-md-offset-37 {
    margin-left: 92.5%;
  }

  .col-md-38 {
    box-sizing: border-box;
    max-width: 95%;
    flex-basis: 95%;
    padding: 0;
  }

  .col-md-offset-38 {
    margin-left: 95%;
  }

  .col-md-39 {
    box-sizing: border-box;
    max-width: 97.5%;
    flex-basis: 97.5%;
    padding: 0;
  }

  .col-md-offset-39 {
    margin-left: 97.5%;
  }

  .col-md-40 {
    box-sizing: border-box;
    max-width: 100%;
    flex-basis: 100%;
    padding: 0;
  }

  .col-md-offset-40 {
    margin-left: 100%;
  }

  .row.start-md {
    justify-content: flex-start;
  }

  .row.center-md {
    justify-content: center;
  }

  .row.end-md {
    justify-content: flex-end;
  }

  .row.top-md {
    align-items: flex-start;
  }

  .row.middle-md {
    align-items: center;
  }

  .row.bottom-md {
    align-items: flex-end;
  }

  .row.around-md {
    justify-content: space-around;
  }

  .row.between-md {
    justify-content: space-between;
  }

  .first-md {
    order: -1;
  }

  .last-md {
    order: 1;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg {
    box-sizing: border-box;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0;
  }

  .col-lg-1 {
    box-sizing: border-box;
    max-width: 2.5%;
    flex-basis: 2.5%;
    padding: 0;
  }

  .col-lg-offset-1 {
    margin-left: 2.5%;
  }

  .col-lg-2 {
    box-sizing: border-box;
    max-width: 5%;
    flex-basis: 5%;
    padding: 0;
  }

  .col-lg-offset-2 {
    margin-left: 5%;
  }

  .col-lg-3 {
    box-sizing: border-box;
    max-width: 7.5%;
    flex-basis: 7.5%;
    padding: 0;
  }

  .col-lg-offset-3 {
    margin-left: 7.5%;
  }

  .col-lg-4 {
    box-sizing: border-box;
    max-width: 10%;
    flex-basis: 10%;
    padding: 0;
  }

  .col-lg-offset-4 {
    margin-left: 10%;
  }

  .col-lg-5 {
    box-sizing: border-box;
    max-width: 12.5%;
    flex-basis: 12.5%;
    padding: 0;
  }

  .col-lg-offset-5 {
    margin-left: 12.5%;
  }

  .col-lg-6 {
    box-sizing: border-box;
    max-width: 15%;
    flex-basis: 15%;
    padding: 0;
  }

  .col-lg-offset-6 {
    margin-left: 15%;
  }

  .col-lg-7 {
    box-sizing: border-box;
    max-width: 17.5%;
    flex-basis: 17.5%;
    padding: 0;
  }

  .col-lg-offset-7 {
    margin-left: 17.5%;
  }

  .col-lg-8 {
    box-sizing: border-box;
    max-width: 20%;
    flex-basis: 20%;
    padding: 0;
  }

  .col-lg-offset-8 {
    margin-left: 20%;
  }

  .col-lg-9 {
    box-sizing: border-box;
    max-width: 22.5%;
    flex-basis: 22.5%;
    padding: 0;
  }

  .col-lg-offset-9 {
    margin-left: 22.5%;
  }

  .col-lg-10 {
    box-sizing: border-box;
    max-width: 25%;
    flex-basis: 25%;
    padding: 0;
  }

  .col-lg-offset-10 {
    margin-left: 25%;
  }

  .col-lg-11 {
    box-sizing: border-box;
    max-width: 27.5%;
    flex-basis: 27.5%;
    padding: 0;
  }

  .col-lg-offset-11 {
    margin-left: 27.5%;
  }

  .col-lg-12 {
    box-sizing: border-box;
    max-width: 30%;
    flex-basis: 30%;
    padding: 0;
  }

  .col-lg-offset-12 {
    margin-left: 30%;
  }

  .col-lg-13 {
    box-sizing: border-box;
    max-width: 32.5%;
    flex-basis: 32.5%;
    padding: 0;
  }

  .col-lg-offset-13 {
    margin-left: 32.5%;
  }

  .col-lg-14 {
    box-sizing: border-box;
    max-width: 35%;
    flex-basis: 35%;
    padding: 0;
  }

  .col-lg-offset-14 {
    margin-left: 35%;
  }

  .col-lg-15 {
    box-sizing: border-box;
    max-width: 37.5%;
    flex-basis: 37.5%;
    padding: 0;
  }

  .col-lg-offset-15 {
    margin-left: 37.5%;
  }

  .col-lg-16 {
    box-sizing: border-box;
    max-width: 40%;
    flex-basis: 40%;
    padding: 0;
  }

  .col-lg-offset-16 {
    margin-left: 40%;
  }

  .col-lg-17 {
    box-sizing: border-box;
    max-width: 42.5%;
    flex-basis: 42.5%;
    padding: 0;
  }

  .col-lg-offset-17 {
    margin-left: 42.5%;
  }

  .col-lg-18 {
    box-sizing: border-box;
    max-width: 45%;
    flex-basis: 45%;
    padding: 0;
  }

  .col-lg-offset-18 {
    margin-left: 45%;
  }

  .col-lg-19 {
    box-sizing: border-box;
    max-width: 47.5%;
    flex-basis: 47.5%;
    padding: 0;
  }

  .col-lg-offset-19 {
    margin-left: 47.5%;
  }

  .col-lg-20 {
    box-sizing: border-box;
    max-width: 50%;
    flex-basis: 50%;
    padding: 0;
  }

  .col-lg-offset-20 {
    margin-left: 50%;
  }

  .col-lg-21 {
    box-sizing: border-box;
    max-width: 52.5%;
    flex-basis: 52.5%;
    padding: 0;
  }

  .col-lg-offset-21 {
    margin-left: 52.5%;
  }

  .col-lg-22 {
    box-sizing: border-box;
    max-width: 55%;
    flex-basis: 55%;
    padding: 0;
  }

  .col-lg-offset-22 {
    margin-left: 55%;
  }

  .col-lg-23 {
    box-sizing: border-box;
    max-width: 57.5%;
    flex-basis: 57.5%;
    padding: 0;
  }

  .col-lg-offset-23 {
    margin-left: 57.5%;
  }

  .col-lg-24 {
    box-sizing: border-box;
    max-width: 60%;
    flex-basis: 60%;
    padding: 0;
  }

  .col-lg-offset-24 {
    margin-left: 60%;
  }

  .col-lg-25 {
    box-sizing: border-box;
    max-width: 62.5%;
    flex-basis: 62.5%;
    padding: 0;
  }

  .col-lg-offset-25 {
    margin-left: 62.5%;
  }

  .col-lg-26 {
    box-sizing: border-box;
    max-width: 65%;
    flex-basis: 65%;
    padding: 0;
  }

  .col-lg-offset-26 {
    margin-left: 65%;
  }

  .col-lg-27 {
    box-sizing: border-box;
    max-width: 67.5%;
    flex-basis: 67.5%;
    padding: 0;
  }

  .col-lg-offset-27 {
    margin-left: 67.5%;
  }

  .col-lg-28 {
    box-sizing: border-box;
    max-width: 70%;
    flex-basis: 70%;
    padding: 0;
  }

  .col-lg-offset-28 {
    margin-left: 70%;
  }

  .col-lg-29 {
    box-sizing: border-box;
    max-width: 72.5%;
    flex-basis: 72.5%;
    padding: 0;
  }

  .col-lg-offset-29 {
    margin-left: 72.5%;
  }

  .col-lg-30 {
    box-sizing: border-box;
    max-width: 75%;
    flex-basis: 75%;
    padding: 0;
  }

  .col-lg-offset-30 {
    margin-left: 75%;
  }

  .col-lg-31 {
    box-sizing: border-box;
    max-width: 77.5%;
    flex-basis: 77.5%;
    padding: 0;
  }

  .col-lg-offset-31 {
    margin-left: 77.5%;
  }

  .col-lg-32 {
    box-sizing: border-box;
    max-width: 80%;
    flex-basis: 80%;
    padding: 0;
  }

  .col-lg-offset-32 {
    margin-left: 80%;
  }

  .col-lg-33 {
    box-sizing: border-box;
    max-width: 82.5%;
    flex-basis: 82.5%;
    padding: 0;
  }

  .col-lg-offset-33 {
    margin-left: 82.5%;
  }

  .col-lg-34 {
    box-sizing: border-box;
    max-width: 85%;
    flex-basis: 85%;
    padding: 0;
  }

  .col-lg-offset-34 {
    margin-left: 85%;
  }

  .col-lg-35 {
    box-sizing: border-box;
    max-width: 87.5%;
    flex-basis: 87.5%;
    padding: 0;
  }

  .col-lg-offset-35 {
    margin-left: 87.5%;
  }

  .col-lg-36 {
    box-sizing: border-box;
    max-width: 90%;
    flex-basis: 90%;
    padding: 0;
  }

  .col-lg-offset-36 {
    margin-left: 90%;
  }

  .col-lg-37 {
    box-sizing: border-box;
    max-width: 92.5%;
    flex-basis: 92.5%;
    padding: 0;
  }

  .col-lg-offset-37 {
    margin-left: 92.5%;
  }

  .col-lg-38 {
    box-sizing: border-box;
    max-width: 95%;
    flex-basis: 95%;
    padding: 0;
  }

  .col-lg-offset-38 {
    margin-left: 95%;
  }

  .col-lg-39 {
    box-sizing: border-box;
    max-width: 97.5%;
    flex-basis: 97.5%;
    padding: 0;
  }

  .col-lg-offset-39 {
    margin-left: 97.5%;
  }

  .col-lg-40 {
    box-sizing: border-box;
    max-width: 100%;
    flex-basis: 100%;
    padding: 0;
  }

  .col-lg-offset-40 {
    margin-left: 100%;
  }

  .row.start-lg {
    justify-content: flex-start;
  }

  .row.center-lg {
    justify-content: center;
  }

  .row.end-lg {
    justify-content: flex-end;
  }

  .row.top-lg {
    align-items: flex-start;
  }

  .row.middle-lg {
    align-items: center;
  }

  .row.bottom-lg {
    align-items: flex-end;
  }

  .row.around-lg {
    justify-content: space-around;
  }

  .row.between-lg {
    justify-content: space-between;
  }

  .first-lg {
    order: -1;
  }

  .last-lg {
    order: 1;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl {
    box-sizing: border-box;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0;
  }

  .col-xl-1 {
    box-sizing: border-box;
    max-width: 2.5%;
    flex-basis: 2.5%;
    padding: 0;
  }

  .col-xl-offset-1 {
    margin-left: 2.5%;
  }

  .col-xl-2 {
    box-sizing: border-box;
    max-width: 5%;
    flex-basis: 5%;
    padding: 0;
  }

  .col-xl-offset-2 {
    margin-left: 5%;
  }

  .col-xl-3 {
    box-sizing: border-box;
    max-width: 7.5%;
    flex-basis: 7.5%;
    padding: 0;
  }

  .col-xl-offset-3 {
    margin-left: 7.5%;
  }

  .col-xl-4 {
    box-sizing: border-box;
    max-width: 10%;
    flex-basis: 10%;
    padding: 0;
  }

  .col-xl-offset-4 {
    margin-left: 10%;
  }

  .col-xl-5 {
    box-sizing: border-box;
    max-width: 12.5%;
    flex-basis: 12.5%;
    padding: 0;
  }

  .col-xl-offset-5 {
    margin-left: 12.5%;
  }

  .col-xl-6 {
    box-sizing: border-box;
    max-width: 15%;
    flex-basis: 15%;
    padding: 0;
  }

  .col-xl-offset-6 {
    margin-left: 15%;
  }

  .col-xl-7 {
    box-sizing: border-box;
    max-width: 17.5%;
    flex-basis: 17.5%;
    padding: 0;
  }

  .col-xl-offset-7 {
    margin-left: 17.5%;
  }

  .col-xl-8 {
    box-sizing: border-box;
    max-width: 20%;
    flex-basis: 20%;
    padding: 0;
  }

  .col-xl-offset-8 {
    margin-left: 20%;
  }

  .col-xl-9 {
    box-sizing: border-box;
    max-width: 22.5%;
    flex-basis: 22.5%;
    padding: 0;
  }

  .col-xl-offset-9 {
    margin-left: 22.5%;
  }

  .col-xl-10 {
    box-sizing: border-box;
    max-width: 25%;
    flex-basis: 25%;
    padding: 0;
  }

  .col-xl-offset-10 {
    margin-left: 25%;
  }

  .col-xl-11 {
    box-sizing: border-box;
    max-width: 27.5%;
    flex-basis: 27.5%;
    padding: 0;
  }

  .col-xl-offset-11 {
    margin-left: 27.5%;
  }

  .col-xl-12 {
    box-sizing: border-box;
    max-width: 30%;
    flex-basis: 30%;
    padding: 0;
  }

  .col-xl-offset-12 {
    margin-left: 30%;
  }

  .col-xl-13 {
    box-sizing: border-box;
    max-width: 32.5%;
    flex-basis: 32.5%;
    padding: 0;
  }

  .col-xl-offset-13 {
    margin-left: 32.5%;
  }

  .col-xl-14 {
    box-sizing: border-box;
    max-width: 35%;
    flex-basis: 35%;
    padding: 0;
  }

  .col-xl-offset-14 {
    margin-left: 35%;
  }

  .col-xl-15 {
    box-sizing: border-box;
    max-width: 37.5%;
    flex-basis: 37.5%;
    padding: 0;
  }

  .col-xl-offset-15 {
    margin-left: 37.5%;
  }

  .col-xl-16 {
    box-sizing: border-box;
    max-width: 40%;
    flex-basis: 40%;
    padding: 0;
  }

  .col-xl-offset-16 {
    margin-left: 40%;
  }

  .col-xl-17 {
    box-sizing: border-box;
    max-width: 42.5%;
    flex-basis: 42.5%;
    padding: 0;
  }

  .col-xl-offset-17 {
    margin-left: 42.5%;
  }

  .col-xl-18 {
    box-sizing: border-box;
    max-width: 45%;
    flex-basis: 45%;
    padding: 0;
  }

  .col-xl-offset-18 {
    margin-left: 45%;
  }

  .col-xl-19 {
    box-sizing: border-box;
    max-width: 47.5%;
    flex-basis: 47.5%;
    padding: 0;
  }

  .col-xl-offset-19 {
    margin-left: 47.5%;
  }

  .col-xl-20 {
    box-sizing: border-box;
    max-width: 50%;
    flex-basis: 50%;
    padding: 0;
  }

  .col-xl-offset-20 {
    margin-left: 50%;
  }

  .col-xl-21 {
    box-sizing: border-box;
    max-width: 52.5%;
    flex-basis: 52.5%;
    padding: 0;
  }

  .col-xl-offset-21 {
    margin-left: 52.5%;
  }

  .col-xl-22 {
    box-sizing: border-box;
    max-width: 55%;
    flex-basis: 55%;
    padding: 0;
  }

  .col-xl-offset-22 {
    margin-left: 55%;
  }

  .col-xl-23 {
    box-sizing: border-box;
    max-width: 57.5%;
    flex-basis: 57.5%;
    padding: 0;
  }

  .col-xl-offset-23 {
    margin-left: 57.5%;
  }

  .col-xl-24 {
    box-sizing: border-box;
    max-width: 60%;
    flex-basis: 60%;
    padding: 0;
  }

  .col-xl-offset-24 {
    margin-left: 60%;
  }

  .col-xl-25 {
    box-sizing: border-box;
    max-width: 62.5%;
    flex-basis: 62.5%;
    padding: 0;
  }

  .col-xl-offset-25 {
    margin-left: 62.5%;
  }

  .col-xl-26 {
    box-sizing: border-box;
    max-width: 65%;
    flex-basis: 65%;
    padding: 0;
  }

  .col-xl-offset-26 {
    margin-left: 65%;
  }

  .col-xl-27 {
    box-sizing: border-box;
    max-width: 67.5%;
    flex-basis: 67.5%;
    padding: 0;
  }

  .col-xl-offset-27 {
    margin-left: 67.5%;
  }

  .col-xl-28 {
    box-sizing: border-box;
    max-width: 70%;
    flex-basis: 70%;
    padding: 0;
  }

  .col-xl-offset-28 {
    margin-left: 70%;
  }

  .col-xl-29 {
    box-sizing: border-box;
    max-width: 72.5%;
    flex-basis: 72.5%;
    padding: 0;
  }

  .col-xl-offset-29 {
    margin-left: 72.5%;
  }

  .col-xl-30 {
    box-sizing: border-box;
    max-width: 75%;
    flex-basis: 75%;
    padding: 0;
  }

  .col-xl-offset-30 {
    margin-left: 75%;
  }

  .col-xl-31 {
    box-sizing: border-box;
    max-width: 77.5%;
    flex-basis: 77.5%;
    padding: 0;
  }

  .col-xl-offset-31 {
    margin-left: 77.5%;
  }

  .col-xl-32 {
    box-sizing: border-box;
    max-width: 80%;
    flex-basis: 80%;
    padding: 0;
  }

  .col-xl-offset-32 {
    margin-left: 80%;
  }

  .col-xl-33 {
    box-sizing: border-box;
    max-width: 82.5%;
    flex-basis: 82.5%;
    padding: 0;
  }

  .col-xl-offset-33 {
    margin-left: 82.5%;
  }

  .col-xl-34 {
    box-sizing: border-box;
    max-width: 85%;
    flex-basis: 85%;
    padding: 0;
  }

  .col-xl-offset-34 {
    margin-left: 85%;
  }

  .col-xl-35 {
    box-sizing: border-box;
    max-width: 87.5%;
    flex-basis: 87.5%;
    padding: 0;
  }

  .col-xl-offset-35 {
    margin-left: 87.5%;
  }

  .col-xl-36 {
    box-sizing: border-box;
    max-width: 90%;
    flex-basis: 90%;
    padding: 0;
  }

  .col-xl-offset-36 {
    margin-left: 90%;
  }

  .col-xl-37 {
    box-sizing: border-box;
    max-width: 92.5%;
    flex-basis: 92.5%;
    padding: 0;
  }

  .col-xl-offset-37 {
    margin-left: 92.5%;
  }

  .col-xl-38 {
    box-sizing: border-box;
    max-width: 95%;
    flex-basis: 95%;
    padding: 0;
  }

  .col-xl-offset-38 {
    margin-left: 95%;
  }

  .col-xl-39 {
    box-sizing: border-box;
    max-width: 97.5%;
    flex-basis: 97.5%;
    padding: 0;
  }

  .col-xl-offset-39 {
    margin-left: 97.5%;
  }

  .col-xl-40 {
    box-sizing: border-box;
    max-width: 100%;
    flex-basis: 100%;
    padding: 0;
  }

  .col-xl-offset-40 {
    margin-left: 100%;
  }

  .row.start-xl {
    justify-content: flex-start;
  }

  .row.center-xl {
    justify-content: center;
  }

  .row.end-xl {
    justify-content: flex-end;
  }

  .row.top-xl {
    align-items: flex-start;
  }

  .row.middle-xl {
    align-items: center;
  }

  .row.bottom-xl {
    align-items: flex-end;
  }

  .row.around-xl {
    justify-content: space-around;
  }

  .row.between-xl {
    justify-content: space-between;
  }

  .first-xl {
    order: -1;
  }

  .last-xl {
    order: 1;
  }
}

.col-gutter-lr, .col-no-gutter {
  padding: 0;
}

.show {
  display: block !important;
}

.row.show {
  display: flex !important;
}

.hide {
  display: none !important;
}

.show-xs {
  display: block !important;
}

.row.show-xs {
  display: flex !important;
}

.hide-xs {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .show-xs-only {
    display: block !important;
  }

  .row.show-xs-only {
    display: flex !important;
  }

  .hide-xs-only {
    display: none !important;
  }
}

@media only screen and (min-width: 641px) {
  .show-sm {
    display: block !important;
  }

  .row.show-sm {
    display: flex !important;
  }

  .hide-sm {
    display: none !important;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1023px) {
  .show-sm-only {
    display: block !important;
  }

  .row.show-sm-only {
    display: flex !important;
  }

  .hide-sm-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1024px) {
  .show-md {
    display: block !important;
  }

  .row.show-md {
    display: flex !important;
  }

  .hide-md {
    display: none !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .show-md-only {
    display: block !important;
  }

  .row.show-md-only {
    display: flex !important;
  }

  .hide-md-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1440px) {
  .show-lg {
    display: block !important;
  }

  .row.show-lg {
    display: flex !important;
  }

  .hide-lg {
    display: none !important;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1790px) {
  .show-lg-only {
    display: block !important;
  }

  .row.show-lg-only {
    display: flex !important;
  }

  .hide-lg-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1791px) {
  .show-xl {
    display: block !important;
  }

  .row.show-xl {
    display: flex !important;
  }

  .hide-xl {
    display: none !important;
  }
}

#CybotCookiebotDialog {
  z-index: 100 !important;
}

#CybotCookiebotDialog.CybotEdge {
  border-radius: 0;
  z-index: 100 !important;
  padding: 15px !important;
}

@media screen and (min-width: 1280px) {
  #CybotCookiebotDialog.CybotEdge {
    padding: 15px;
  }
}

#CybotCookiebotDialog.CybotEdge * {
  vertical-align: middle;
  font-size: .6rem;
}

#CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper {
  align-items: center;
}

#CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogBodyContentText a {
  color: inherit;
  border-bottom: 1px solid #ff623a;
}

#CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogHeader {
  width: auto;
  border: 0;
  padding: 0;
}

#CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogHeaderLogosWrapper {
  display: none;
}

#CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogTabContent {
  margin-left: 0;
}

@media screen and (min-width: 1280px) {
  #CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogBodyButtonsWrapper {
    flex-direction: row !important;
  }
}

#CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper .CybotCookiebotDialogBodyBottomWrapper, #CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogBodyEdgeMoreDetails, #CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogBodyContentTitle {
  display: none;
}

#CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogBodyLevelButtonCustomize {
  border-width: 1px;
}

#CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  letter-spacing: .02em;
  border-width: 1px;
  padding: .75em;
  font-weight: normal;
}

#CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton .CybotCookiebotDialogArrow {
  display: none;
}

#CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper .CybotCookiebotScrollContainer {
  min-height: initial;
}

@media screen and (min-width: 601px) {
  #CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyButton:not(:last-of-type), #CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton, #CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton:not(:first-of-type) {
    margin-bottom: 0;
  }
}

#CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogBody .CybotCookiebotScrollContainer {
  padding: 0;
}

:root {
  --base-font-size: 14;
  --base-font-size-vw: 3.358;
}

@media only screen and (min-width: 641px) {
  :root {
    --base-font-size: 14;
    --base-font-size-vw: 2.187;
  }
}

@media only screen and (min-width: 1024px) {
  :root {
    --base-font-size: 22;
    --base-font-size-vw: 1.527;
  }
}

p, h1, h2, h3, h4, h5, h6, ul, ol, li {
  margin: 0;
  padding: 0;
}

* {
  font-size: calc((14 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  font-family: Roboto Serif, serif;
  font-weight: 200;
  line-height: 1.2;
}

@media only screen and (min-width: 641px) {
  * {
    font-size: calc((22 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  }
}

ul, ul *, ol, ol *, li, li *, p, p *, .body-text, .body-text * {
  font-size: calc((14 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  letter-spacing: .01em;
  line-height: 1.4;
}

@media only screen and (min-width: 641px) {
  ul, ul *, ol, ol *, li, li *, p, p *, .body-text, .body-text * {
    font-size: calc((22 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  }
}

.link-style, a {
  color: inherit;
  text-underline-offset: 2px;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
}

@media only screen and (min-width: 641px) {
  .link-style, a {
    text-underline-offset: 3px;
  }
}

.link-style:hover, a:hover {
  cursor: pointer;
  color: #ff623a;
  text-decoration-color: #ff623a;
}

b, strong {
  font-weight: 500;
}

.color-text-orange {
  color: #ff623a;
}

.color-text-green {
  color: #00a22a;
}

.color-text-green-lime {
  color: #e5f856;
}

.color-text-violet {
  color: #e390f0;
}

.color-text-blue {
  color: #547cdc;
}

.color-text-hover-orange:hover {
  color: #ff623a !important;
  text-decoration-color: #ff623a !important;
}

.color-text-hover-green:hover {
  color: #00a22a !important;
  text-decoration-color: #00a22a !important;
}

.color-text-hover-green-lime:hover {
  color: #e5f856 !important;
  text-decoration-color: #e5f856 !important;
}

.color-text-hover-violet:hover {
  color: #e390f0 !important;
  text-decoration-color: #e390f0 !important;
}

.color-text-hover-blue:hover {
  color: #547cdc !important;
  text-decoration-color: #547cdc !important;
}

.text-underline {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.body-text--variant, .call__intro p, .article__story-heading-details p, .about__intro p, .body-text--variant *, .call__intro p *, .article__story-heading-details p *, .about__intro p * {
  font-size: calc((19 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  letter-spacing: .01em;
  line-height: 1.2;
}

@media only screen and (min-width: 641px) {
  .body-text--variant, .call__intro p, .article__story-heading-details p, .about__intro p, .body-text--variant *, .call__intro p *, .article__story-heading-details p *, .about__intro p * {
    font-size: calc((27.7895 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
    line-height: 1.25;
  }
}

.terms-text--variant, .cookie-policy, .terms-and-conditions, .terms-text--variant *, .cookie-policy *, .terms-and-conditions * {
  font-size: calc((12 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  letter-spacing: .01em;
  line-height: 1.2;
}

@media only screen and (min-width: 641px) {
  .terms-text--variant, .cookie-policy, .terms-and-conditions, .terms-text--variant *, .cookie-policy *, .terms-and-conditions * {
    font-size: calc((13.8947 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
    line-height: 1.25;
  }
}

p + p {
  padding-top: 1.1em;
}

.highlight-text, .highlight-text * {
  color: #00a22a;
  text-transform: uppercase;
  font-family: Alte Haas Grotesk;
  font-weight: bold;
  font-size: calc((16 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
}

@media only screen and (min-width: 641px) {
  .highlight-text, .highlight-text * {
    font-size: calc((22 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  }
}

.h1-text-style, .h1-text-style *, h1, h1 * {
  font-family: Alte Haas Grotesk;
  font-size: calc((23 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  text-transform: uppercase;
  border-top: 1px dotted;
  border-bottom: 1px dotted;
  padding-top: .5em;
  padding-bottom: .5em;
  font-weight: bold;
  line-height: 1.1;
}

@media only screen and (min-width: 1024px) {
  .h1-text-style, .h1-text-style *, h1, h1 * {
    font-size: calc((34.7368 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  }
}

.h2-text-style, .h2-text-style *, h2, h2 * {
  font-family: Alte Haas Grotesk;
  font-size: calc((90 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  letter-spacing: -.05em;
  line-height: .85;
}

@media only screen and (min-width: 641px) {
  .h2-text-style, .h2-text-style *, h2, h2 * {
    font-size: calc((150.526 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
    line-height: .9;
  }
}

.h3-text-style, .h3-text-style *, h3, h3 * {
  font-family: Alte Haas Grotesk;
  font-size: calc((56 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  letter-spacing: -.06em;
  text-transform: uppercase;
  line-height: .9;
}

@media only screen and (min-width: 1024px) {
  .h3-text-style, .h3-text-style *, h3, h3 * {
    font-size: calc((98.4211 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  }
}

.h4-text-style, .above-fold__text-title, .h4-text-style *, .above-fold__text-title *, h4, h4 * {
  font-weight: 300;
  font-size: min((36 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw, 7vh);
  line-height: 1.2;
}

@media only screen and (min-width: 1024px) {
  .h4-text-style, .above-fold__text-title, .h4-text-style *, .above-fold__text-title *, h4, h4 * {
    font-size: min((48.6316 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw, 6vh);
    line-height: .9;
  }
}

.h5-text-style, .h5-text-style *, h5, h5 *, blockquote, blockquote * {
  color: #00a22a;
  font-weight: 300;
  font-size: calc((30 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  line-height: 1.1;
}

@media only screen and (min-width: 641px) {
  .h5-text-style, .h5-text-style *, h5, h5 *, blockquote, blockquote * {
    font-size: calc((46.3158 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  }
}

.h6-text-style, .h6-text-style *, h6, h6 * {
  font-family: Alte Haas Grotesk;
  font-size: calc((16 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.1;
}

@media only screen and (min-width: 1024px) {
  .h6-text-style, .h6-text-style *, h6, h6 * {
    font-size: calc((27.7895 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  }
}

.h6-text-style + p, .h6-text-style + ol, .h6-text-style + ul, h6 + p, h6 + ol, h6 + ul {
  padding-top: .35em;
}

p + .h6-text-style, ol + .h6-text-style, ul + .h6-text-style, p + h6, ol + h6, ul + h6 {
  padding-top: 1em;
}

.h6-text-style + h1, .h6-text-style + h2, .h6-text-style + h3, h6 + h1, h6 + h2, h6 + h3 {
  padding-top: .5rem;
}

.header-text-style, .header-mobile, .header, .header-text-style *, .header-mobile *, .header * {
  font-family: Alte Haas Grotesk;
  font-size: calc((28 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  letter-spacing: -.03em;
  text-transform: uppercase;
  line-height: .9;
}

@media only screen and (min-width: 1024px) {
  .header-text-style, .header-mobile, .header, .header-text-style *, .header-mobile *, .header * {
    font-size: calc((25.4737 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  }
}

.footer-text-style, .footer, .footer-text-style *, .footer * {
  font-family: Alte Haas Grotesk;
  font-size: calc((14 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  line-height: 1.2;
}

@media only screen and (min-width: 1024px) {
  .footer-text-style, .footer, .footer-text-style *, .footer * {
    font-size: calc((17.3684 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
    letter-spacing: -.02em;
  }
}

.btn-big-text-style, .btn.btn--big, .btn-big-text-style *, .btn.btn--big *, btn-big, btn-big * {
  font-family: Alte Haas Grotesk;
  font-size: calc((56 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  letter-spacing: -.06em;
  line-height: .9;
}

@media only screen and (min-width: 641px) {
  .btn-big-text-style, .btn.btn--big, .btn-big-text-style *, .btn.btn--big *, btn-big, btn-big * {
    font-size: calc((150.526 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  }
}

.btn-text-style, .btn, .btn-text-style *, .btn *, btn, btn * {
  font-family: Alte Haas Grotesk;
  font-size: calc((33 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  letter-spacing: -.06em;
  line-height: .85;
}

@media only screen and (min-width: 1024px) {
  .btn-text-style, .btn, .btn-text-style *, .btn *, btn, btn * {
    font-size: calc((69.4737 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  }
}

html {
  scroll-behavior: smooth;
}

body {
  color: #f3f0e7;
  background-color: #231100;
  overflow-x: hidden;
}

img {
  max-inline-size: 100%;
}

ol {
  counter-reset: item;
}

ol li {
  display: block;
}

ol li:before {
  content: counter(item) ") ";
  counter-increment: item;
}

blockquote {
  text-align: center;
  margin: 4rem 0;
}

@media only screen and (min-width: 641px) {
  blockquote {
    text-align: left;
  }
}

.row {
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (min-width: 1024px) {
  .row {
    padding-left: 20px;
    padding-right: 20px;
  }

  .col-md-offset-0 {
    padding-left: 0;
  }
}

.btn {
  -webkit-appearance: none;
  appearance: none;
  width: auto;
  text-align: center;
  color: #231100;
  text-transform: uppercase;
  background-color: #ff623a;
  border: 0;
  border-radius: 2em;
  outline: 0;
  margin-top: 6rem;
  padding: .4em 2em;
  text-decoration: none;
  transition: transform .5s;
  display: inline-block;
}

.btn:hover {
  cursor: pointer;
  color: #231100;
  background-color: #f3f0e7;
}

@media only screen and (min-width: 1024px) {
  .btn {
    margin-top: 5rem;
    padding: .4em 2.5em;
  }
}

.btn.btn--big {
  width: 100%;
  margin-top: 3rem;
  padding: .6em;
}

@media only screen and (min-width: 1024px) {
  .btn.btn--big {
    margin-top: 5rem;
    padding: .25em;
  }
}

.btn--violet {
  background-color: #e390f0;
}

.btn--green {
  background-color: #00a22a;
}

.btn--blue {
  background-color: #547cdc;
}

.io-trigger {
  width: 100%;
  height: 1px;
  background-color: #0000;
  margin-bottom: -1px;
  position: relative;
}

.float-effect {
  transform-origin: center;
  animation-name: animate-float;
  animation-duration: 13s;
  animation-iteration-count: infinite;
}

@keyframes animate-float {
  0% {
    transform: rotate(10deg)translate(0);
  }

  20% {
    transform: rotate(5deg)translate(.03rem, -1.7rem);
  }

  50% {
    transform: rotate(1deg)translate(-.05rem, -.1rem);
  }

  70% {
    transform: rotate(-1deg)translate(-.05rem, -1.2rem);
  }

  100% {
    transform: rotate(10deg)translate(0);
  }
}

.cookie-policy--open .cookie-policy, .terms-and-conditions--open .terms-and-conditions {
  transform: translateX(0%);
}

.cookie-policy [data-cookie-policy-open], .cookie-policy .CookieDeclarationDialogText {
  display: none;
}

.cookie-policy table.CookieDeclarationTable * {
  font-size: .5rem;
}

.cookie-policy, .terms-and-conditions {
  width: 95%;
  max-width: 50em;
  height: 100vh;
  color: #231100;
  z-index: 110;
  background-color: #f3f0e7;
  transition: transform .3s ease-out;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX(105%);
  box-shadow: -8px 1px 10px #23110022;
}

.cookie-policy__content, .terms-and-conditions__content {
  width: 100%;
  height: 100%;
  padding: 4em calc(20px + 1.5em) 20px 20px;
  overflow-y: auto;
}

.cookie-policy__close, .terms-and-conditions__close {
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  top: 10px;
  right: 20px;
}

@media only screen and (min-width: 641px) {
  .cookie-policy__close, .terms-and-conditions__close {
    top: 20px;
    right: 20px;
  }
}

.cookie-policy__close:after, .cookie-policy__close:before, .terms-and-conditions__close:after, .terms-and-conditions__close:before {
  content: "";
  width: 100%;
  height: 1px;
  transform-origin: 50%;
  background-color: #231100;
  display: block;
  position: absolute;
}

.cookie-policy__close:before, .terms-and-conditions__close:before {
  margin-top: 45%;
  transform: rotate(-45deg);
}

.cookie-policy__close:after, .terms-and-conditions__close:after {
  margin-top: 45%;
  transform: rotate(45deg);
}

.cookie-policy__close:hover, .terms-and-conditions__close:hover {
  cursor: pointer;
}

.cookie-policy__close:hover:after, .cookie-policy__close:hover:before, .terms-and-conditions__close:hover:after, .terms-and-conditions__close:hover:before {
  background-color: #ff623a;
}

.header {
  z-index: 10;
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
}

.header a {
  text-decoration: none;
}

.header .nav {
  width: 100%;
  height: 1.2em;
}

.header .nav__btn {
  color: #231100;
  -webkit-user-select: none;
  user-select: none;
  background-color: #f3f0e7;
  border-radius: 1em;
  padding: .05em .2em;
  font-weight: bold;
}

.header .nav__btn:nth-child(1) {
  position: absolute;
  left: 0;
}

.header .nav__btn:nth-child(1).active, .header .nav__btn:nth-child(1):hover {
  background-color: #ff623a;
}

.header .nav__btn:nth-child(2) {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.header .nav__btn:nth-child(2).active, .header .nav__btn:nth-child(2):hover {
  background-color: #00a22a;
}

.header .nav__btn:nth-child(3) {
  position: absolute;
  right: 0;
}

.header .nav__btn:nth-child(3).active, .header .nav__btn:nth-child(3):hover {
  background-color: #547cdc;
}

.header-mobile {
  z-index: 10;
  position: fixed;
  top: 7.5px;
  left: 7.5px;
  right: 7.5px;
}

.header-mobile a {
  text-decoration: none;
}

.hamb {
  width: .9em;
  margin: .05em 15px 0;
  position: absolute;
}

.header-mobile--open .hamb__hamb {
  display: none;
}

.hamb__close {
  width: 80%;
  margin: .2em 10% 10%;
  display: none;
}

.header-mobile--open .hamb__close {
  display: block;
}

.nav-mobile {
  color: #231100;
  text-align: center;
  min-height: 1.2em;
  width: calc(.9em + 30px);
  background-color: #f3f0e7;
  border-radius: .6em;
  flex-direction: column;
  transition: width .5s ease-in-out;
  display: flex;
  overflow: hidden;
}

.nav-mobile.active {
  width: 100%;
}

.header-mobile--open > .nav-mobile:not(.active) {
  width: 100%;
  transition: width ease-in-out;
}

.nav-mobile a.active {
  color: #231100;
  pointer-events: none;
}

.nav-mobile a:hover {
  color: #231100;
}

.nav-mobile__btn {
  width: calc(100vw - 15px);
  order: 2;
  padding-top: .15em;
  padding-bottom: .15em;
  display: none;
}

.header-mobile--open .nav-mobile__btn {
  display: initial;
}

.nav-mobile__btn.active {
  display: initial;
  order: 1;
  margin-bottom: 0;
  font-weight: bold;
}

.header-mobile--open .nav-mobile__btn.active, .header-mobile--open .nav-mobile__btn:not(:last-child) {
  margin-bottom: .4em;
}

.footer {
  margin-top: 9rem;
  margin-bottom: 15px;
}

@media only screen and (min-width: 641px) {
  .footer {
    margin-top: 10rem;
    margin-bottom: 3.5rem;
  }
}

.footer__link {
  margin-top: .1rem;
  display: block;
}

.footer__social-icons {
  display: flex;
}

.footer__social-icon {
  width: 4em;
  margin-top: 2em;
  padding-right: .5em;
  transition: transform .5s;
}

@media only screen and (min-width: 1024px) {
  .footer__social-icon {
    width: 3.8em;
  }
}

.footer__social-icon:hover {
  transform: scale(1.1);
}

.footer__headline {
  margin-top: 2.6em;
}

.footer__headline, .footer__headline * {
  font-size: calc((17.5 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
}

@media only screen and (min-width: 641px) {
  .footer__headline, .footer__headline * {
    font-size: calc((17.3684 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  }
}

.above-fold {
  width: 100%;
  height: calc(108.25vw + 50vh);
  background-color: #f3f0e7;
  margin-bottom: 8em;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: 1024px) {
  .above-fold {
    height: calc(33.3333vw + 50vh);
  }
}

.above-fold:after {
  content: "";
  z-index: -1;
  background-color: #231100;
  position: absolute;
  inset: 60% 0 0;
}

.above-fold__logo {
  width: 100%;
  min-height: 100vh;
  z-index: 2;
  position: absolute;
}

.above-fold__logo img {
  width: 100%;
  height: 216.5vw;
  margin-top: 1.9vw;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 1024px) {
  .above-fold__logo img {
    height: 66.6667vw;
    margin-top: 3.65vw;
  }
}

.above-fold__bg {
  background-color: #023de7;
  position: absolute;
  inset: 0;
}

.above-fold__bg img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.above-fold__text {
  width: 100%;
  height: 100vh;
  z-index: 2;
  position: absolute;
  top: 0;
}

.above-fold__text-title {
  height: 100%;
  width: 100%;
  color: #e390f0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 27vh;
  display: flex;
}

@media only screen and (min-width: 1024px) {
  .above-fold__text-title {
    margin-top: 0;
  }
}

.above-fold__text-title span {
  opacity: 0;
  animation: 3s forwards fadein;
}

@media only screen and (min-width: 1024px) {
  .above-fold__text-title span {
    margin-bottom: -.1em;
  }
}

.above-fold__text-title span:nth-child(1) {
  animation-delay: 0s;
}

@media only screen and (min-width: 1024px) {
  .above-fold__text-title span:nth-child(1) {
    padding-bottom: .75em;
  }
}

@media only screen and (min-width: 1024px) and (min-aspect-ratio: 18.5 / 9) {
  .above-fold__text-title span:nth-child(1) {
    padding-bottom: .5em;
  }
}

.above-fold__text-title span:nth-child(2) {
  animation-delay: .4s;
}

@media only screen and (min-width: 1024px) {
  .above-fold__text-title span:nth-child(2) {
    margin-bottom: 8.5vw;
    padding-bottom: .75em;
  }
}

@media only screen and (min-width: 1024px) and (min-aspect-ratio: 18.5 / 9) {
  .above-fold__text-title span:nth-child(2) {
    padding-bottom: .5em;
  }
}

.above-fold__text-title span:nth-child(3) {
  animation-delay: .8s;
}

@media only screen and (min-width: 1024px) {
  .above-fold__text-title span:nth-child(3) {
    margin-top: 8.5vw;
    padding-top: .75em;
  }
}

@media only screen and (min-width: 1024px) and (min-aspect-ratio: 18.5 / 9) {
  .above-fold__text-title span:nth-child(3) {
    padding-top: .5em;
  }
}

.above-fold__text-title span:nth-child(4) {
  animation-delay: 1.2s;
}

@media only screen and (min-width: 1024px) {
  .above-fold__text-title span:nth-child(4) {
    padding-top: .75em;
  }
}

@media only screen and (min-width: 1024px) and (min-aspect-ratio: 18.5 / 9) {
  .above-fold__text-title span:nth-child(4) {
    padding-top: .5em;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.about {
  margin-bottom: 6rem;
}

@media only screen and (min-width: 1024px) {
  .about {
    margin-bottom: 2rem;
  }
}

.about h1, .about h2, .about h3, .about h4, .about h5, .about h6, .about .h1-text-style, .about .h2-text-style, .about .h3-text-style, .about .h4-text-style, .about .above-fold__text-title, .about .h5-text-style, .about .h6-text-style {
  color: #ff623a;
}

.about .color-text-hover-orange, .about .color-text-hover-green, .about .color-text-hover-green-lime, .about .color-text-hover-blue, .about .color-text-hover-violet {
  color: #ff623a;
  text-decoration-color: #ff623a;
}

.about__divider {
  margin-bottom: 3rem;
}

.about__intro {
  text-align: center;
}

.about__intro-title {
  margin-bottom: .8em;
}

@media only screen and (min-width: 1024px) {
  .about__intro-title {
    margin-bottom: .5em;
  }
}

.about__intro-text {
  margin-bottom: 2em;
}

.about__follow-section {
  background-color: #ff623a;
  margin-top: 1rem;
}

@media only screen and (min-width: 1024px) {
  .about__follow-section {
    margin-top: 1rem;
    margin-left: 50%;
    padding: .4em 1em;
  }

  .about__follow-section-btns {
    margin-top: -.1em;
  }
}

.about__social-icon {
  width: 4.3rem;
  padding: .25rem;
  transition: transform .5s;
  display: inline-block;
}

@media only screen and (min-width: 1024px) {
  .about__social-icon {
    width: 3rem;
    padding-top: 0;
  }
}

.about__social-icon:hover {
  transform: scale(1.1);
}

.article {
  margin-top: 1rem;
  margin-bottom: 6rem;
}

@media only screen and (min-width: 1024px) {
  .article {
    margin-top: 0;
  }
}

.article h1, .article h2, .article h3, .article h4, .article h5, .article h6, .article .h1-text-style, .article .h2-text-style, .article .h3-text-style, .article .h4-text-style, .article .above-fold__text-title, .article .h5-text-style, .article .h6-text-style {
  color: #00a22a;
}

.article__divider {
  text-align: center;
  margin-bottom: 1.5rem;
}

.article__img img {
  width: 100%;
  border-radius: .5rem;
}

.article__pause {
  width: 5em;
  margin: 3rem auto;
  display: block;
}

.article__story-heading {
  text-align: center;
  margin-bottom: 3rem;
  padding-top: 3rem;
}

@media only screen and (min-width: 1024px) {
  .article__story-heading {
    text-align: left;
  }
}

.article__story-heading-details {
  margin-top: 4em;
  margin-bottom: .25em;
}

@media only screen and (min-width: 1024px) {
  .article__story-heading-details {
    margin-top: 0;
  }
}

.article__story-heading-details p + p {
  padding-top: .8em;
}

.article .btn {
  margin-top: 1em;
  margin-left: 40%;
}

.article .btn span:after {
  content: "More";
}

.article.article--open .btn span:after {
  content: "Less";
}

.article__story-text {
  max-height: 105em;
  height: auto;
  transition: max-height cubic-bezier(.64, 0, .78, 0);
  position: relative;
  overflow: hidden;
}

.article__story-text:after {
  content: "";
  height: 5em;
  width: 100%;
  background-image: linear-gradient(#3d2f2400, #231100e6 80%, #231100 100%);
  transition: opacity .25s .2s;
  position: absolute;
  bottom: 0;
}

.article--open .article__story-text {
  max-height: 1100em;
  transition: max-height 1s cubic-bezier(.64, 0, .78, 0);
}

@media only screen and (min-width: 1024px) {
  .article--open .article__story-text {
    max-height: 660em;
  }
}

.article--open .article__story-text:after {
  opacity: 0;
}

.article__story-text .col-xs-34 {
  position: relative;
}

.article__text-group + .article__text-group {
  margin-top: 1em;
}

.article__illustration-1 {
  width: 70vw;
  margin: 2rem auto;
}

.article__illustration-1 img {
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .article__illustration-1 {
    width: 65%;
    shape-outside: border-box;
    float: right;
    margin-top: 1em;
    margin-right: -40%;
    padding-left: 30px;
    display: block;
    position: relative;
  }
}

.article__illustration-2 {
  width: 60vw;
  margin: 2rem auto;
}

.article__illustration-2 img {
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .article__illustration-2 {
    width: 64%;
    shape-outside: border-box;
    float: right;
    margin-top: .2em;
    margin-right: -30%;
    padding-bottom: 1em;
    padding-left: 30px;
    display: block;
    position: relative;
  }
}

.article__illustration-3 {
  width: 80vw;
  margin: 1rem auto 2rem;
}

.article__illustration-3 img {
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .article__illustration-3 img {
    margin-left: -3em;
  }

  .article__illustration-3 {
    width: 64%;
    margin-right: -32%;
    margin-top: initial;
    shape-outside: border-box;
    float: right;
    display: block;
    position: relative;
  }
}

.article__illustration-4 {
  width: 50vw;
  margin: 2rem auto;
}

.article__illustration-4 img {
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .article__illustration-4 {
    width: 65%;
    shape-outside: border-box;
    float: right;
    margin-top: 2%;
    margin-right: -31%;
    padding-top: 1em;
    padding-bottom: 6em;
    padding-left: 30px;
    display: block;
    position: relative;
  }

  .call {
    padding-top: 2rem;
  }
}

.call h1, .call h2, .call h3, .call h4, .call h5, .call h6, .call .h1-text-style, .call .h2-text-style, .call .h3-text-style, .call .h4-text-style, .call .above-fold__text-title, .call .h5-text-style, .call .h6-text-style {
  color: #547cdc;
}

.call__divider {
  margin-bottom: 3rem;
}

.call__intro {
  text-align: center;
  margin-bottom: 5rem;
}

.call__intro-title {
  margin-bottom: .8em;
}

@media only screen and (min-width: 1024px) {
  .call__intro-title {
    margin-bottom: .4em;
  }
}

.call__text-details {
  margin-top: 3em;
}

@media only screen and (min-width: 1024px) {
  .call__text-details {
    margin-top: 0;
  }

  .call .btn {
    margin-top: 3rem;
  }
}

/*# sourceMappingURL=index.17b1fbb2.css.map */
