.header {
  position: fixed;
  bottom: $page-padding;
  left: $page-padding;
  right: $page-padding;
  z-index: 10;
  @extend .header-text-style;

  a {
    text-decoration: none;
  }

  .nav {
    width: 100%;
    height: calc(1.1em + 0.05em*2);

    &__btn {
      background-color: $color-white;
      color: $color-brown;
      border-radius: 1em;
      padding: 0.05em 0.2em;
      user-select: none;
      font-weight: bold;

      &:nth-child(1) {
        position: absolute;
        left: 0;
        &.active,
        &:hover {
          background-color: $color-orange;
        }
      }
      &:nth-child(2) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        &.active,
        &:hover {
          background-color: $color-green;
        }
      }
      &:nth-child(3) {
        position: absolute;
        right: 0;
        &.active,
        &:hover {
          background-color: $color-blue;
        }
      }
    }
  }
}

.header-mobile {
  position: fixed;
  top: $page-padding--mobile/2;
  left: $page-padding--mobile/2;
  right: $page-padding--mobile/2;
  z-index: 10;
  @extend .header-text-style;

  a {
    text-decoration: none;
  }
}

.hamb {
  position: absolute;
  width: 0.9em;
  margin: 0 $page-padding--mobile;
  margin-top: 0.05em;

  &__hamb {
    .header-mobile--open & {
      display: none;
    }
  }
  &__close {
    width: 80%;
    margin: 10%;
    margin-top: 0.2em;
    display: none;

    .header-mobile--open & {
      display: block;
    }
  }
}

.nav-mobile {
  background-color: $color-white;
  color: $color-brown;
  border-radius: .6em;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: calc(0.9em + 0.15em*2);
  width: calc(0.9em + #{$page-padding--mobile}*2);
  transition: width 0.5s ease-in-out;
  overflow: hidden;

  &.active {
    width: 100%;
  }

  .header-mobile--open > &:not(.active) {
    width: 100%;
    transition: width 0s ease-in-out;
  }

  a.active {
    color: $color-brown;
    pointer-events: none;
  }

  a:hover {
    color: $color-brown;
  }

  &__btn {
    order: 2;
    width: calc(100vw - #{$page-padding--mobile}/2*2);
    padding-top: 0.15em;
    padding-bottom: 0.15em;
    display: none;

    .header-mobile--open & {
      display: initial;
    }

    &.active {
      order: 1;
      font-weight: bold;
      margin-bottom: 0;
      display: initial;

      .header-mobile--open & {
        margin-bottom: 0.4em;
      }
    }

    &:not(:last-child) {

      .header-mobile--open & {
        margin-bottom: 0.4em;
      }
    }
  }
}
