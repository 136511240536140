:root {
  --base-font-size: #{$base-font-size-no-unit--mobile};
  --base-font-size-vw: #{$base-font-size-vw--mobile};

  @media #{$breakpoint-sm-up} {
    --base-font-size: #{$base-font-size-no-unit--tablet};
    --base-font-size-vw: #{$base-font-size-vw--tablet};
  }

  @media #{$breakpoint-md-up} {
    --base-font-size: #{$base-font-size-no-unit--desktop};
    --base-font-size-vw: #{$base-font-size-vw--desktop};
  }
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

ul, ol, li {
  margin: 0;
  padding: 0;
}

* {
  font-size: font-size($p-font-size--mobile);
  font-family: "Roboto Serif", serif;
  font-weight: 200;
  line-height: 1.2;
  // text-rendering: geometricPrecision;

  @media #{$breakpoint-sm-up} {
    font-size: font-size($p-font-size);
  }
}

ul, ul *, ol, ol *, li, li *,
p, p *,
.body-text, .body-text * {
  font-size: font-size($p-font-size--mobile);
  letter-spacing: 0.01em;
  line-height: 1.4;

  @media #{$breakpoint-sm-up} {
    font-size: font-size($p-font-size);
  }
}

.link-style,
a {
  color: inherit;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;

  @media #{$breakpoint-sm-up} {
    text-underline-offset: 3px;
  }

  &:hover {
    cursor: pointer;
    color: $color-orange;
    text-decoration-color: $color-orange;
  }
}

b, strong {
  font-weight: 500;
}

.color-text-orange {
  color: $color-orange;
}
.color-text-green {
  color: $color-green;
}
.color-text-green-lime {
  color: $color-green-lime;
}
.color-text-violet {
  color: $color-violet;
}
.color-text-blue {
  color: $color-blue;
}

.color-text-hover-orange:hover {
  color: $color-orange !important;
  text-decoration-color: $color-orange !important;
}
.color-text-hover-green:hover {
  color: $color-green !important;
  text-decoration-color: $color-green !important;
}
.color-text-hover-green-lime:hover {
  color: $color-green-lime !important;
  text-decoration-color: $color-green-lime !important;
}
.color-text-hover-violet:hover {
  color: $color-violet !important;
  text-decoration-color: $color-violet !important;
}
.color-text-hover-blue:hover {
  color: $color-blue !important;
  text-decoration-color: $color-blue !important;
}

.text-underline {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}


.body-text--variant, .body-text--variant * {
  font-size: font-size($p-variant-font-size--mobile);
  letter-spacing: 0.01em;
  line-height: 1.2;

  @media #{$breakpoint-sm-up} {
    font-size: font-size($p-variant-font-size);
    line-height: 1.25;
  }
}

.terms-text--variant, .terms-text--variant * {
  font-size: font-size($terms-font-size--mobile);
  letter-spacing: 0.01em;
  line-height: 1.2;

  @media #{$breakpoint-sm-up} {
    font-size: font-size($terms-font-size);
    line-height: 1.25;
  }
}

p + p {
  padding-top: 1.1em;
}

.highlight-text, .highlight-text * {
  font-family: "Alte Haas Grotesk";
  color: $color-green;
  text-transform: uppercase;
  font-weight: bold;
  font-size: font-size($h6-font-size--mobile);

  @media #{$breakpoint-sm-up} {
    font-size: font-size($p-font-size);
  }
}

.h1-text-style, .h1-text-style *,
h1, h1 * {
  font-family: "Alte Haas Grotesk";
  font-size: font-size($h1-font-size--mobile);
  line-height: 1.1;
  text-transform: uppercase;
  font-weight: bold;
  border-top: 1px dotted;
  border-bottom: 1px dotted;
  padding-top: 0.5em;
  padding-bottom: 0.5em;

  @media #{$breakpoint-md-up} {
    font-size: font-size($h1-font-size);
  }
}

.h2-text-style, .h2-text-style *,
h2, h2 * {
  font-family: "Alte Haas Grotesk";
  font-size: font-size($h2-font-size--mobile);
  line-height: 0.85;
  letter-spacing: -0.05em;

  @media #{$breakpoint-sm-up} {
    font-size: font-size($h2-font-size);
    line-height: 0.9;
  }
}


.h3-text-style, .h3-text-style *,
h3, h3 * {
  font-family: "Alte Haas Grotesk";
  font-size: font-size($h3-font-size--mobile);
  line-height: 0.9;
  letter-spacing: -0.06em;
  text-transform: uppercase;

  @media #{$breakpoint-md-up} {
    font-size: font-size($h3-font-size);
  }
}


.h4-text-style, .h4-text-style *,
h4, h4 * {
  font-weight: 300;
  font-size: min(font-size($h4-font-size--mobile), 7vh);
  line-height: 1.2;

  @media #{$breakpoint-md-up} {
    font-size: min(font-size($h4-font-size), 6vh);
    line-height: 0.9;
  }
}

.h5-text-style, .h5-text-style *,
h5, h5 *,
blockquote, blockquote * {
  color: $color-green;
  font-weight: 300;
  font-size: font-size($h5-font-size--mobile);
  line-height: 1.1;

  @media #{$breakpoint-sm-up} {
    font-size: font-size($h5-font-size);
  }
}

.h6-text-style, .h6-text-style *,
h6, h6 * {
  font-family: "Alte Haas Grotesk";
  font-size: font-size($h6-font-size--mobile);
  line-height: 1.1;
  text-transform: uppercase;
  font-weight: bold;

  @media #{$breakpoint-md-up} {
    font-size: font-size($h6-font-size);
  }
}

.h6-text-style + p,
.h6-text-style + ol,
.h6-text-style + ul,
h6 + p,
h6 + ol,
h6 + ul {
  padding-top: 0.35em;
}
p + .h6-text-style,
ol + .h6-text-style,
ul + .h6-text-style,
p + h6,
ol + h6,
ul + h6 {
  padding-top: 1em;
}

.h6-text-style + h1,
.h6-text-style + h2,
.h6-text-style + h3,
h6 + h1,
h6 + h2,
h6 + h3 {
  padding-top: 0.5rem;
}

.header-text-style, .header-text-style * {
  font-family: "Alte Haas Grotesk";
  font-size: font-size($header-font-size--mobile);
  line-height: 0.9;
  letter-spacing: -0.03em;
  text-transform: uppercase;

  @media #{$breakpoint-md-up} {
    font-size: font-size($header-font-size);
  }
}

.footer-text-style, .footer-text-style * {
  font-family: "Alte Haas Grotesk";
  font-size: font-size($footer-font-size--mobile);
  line-height: 1.2;

  @media #{$breakpoint-md-up} {
    font-size: font-size($footer-font-size);
    letter-spacing: -0.02em;
  }
}



.btn-big-text-style, .btn-big-text-style *,
btn-big, btn-big * {
  font-family: "Alte Haas Grotesk";
  font-size: font-size($btn-big-font-size--mobile);
  line-height: 0.9;
  letter-spacing: -0.06em;

  @media #{$breakpoint-sm-up} {
    font-size: font-size($btn-big-font-size);
  }
}

.btn-text-style, .btn-text-style *,
btn, btn * {
  font-family: "Alte Haas Grotesk";
  font-size: font-size($btn-font-size--mobile);
  line-height: 0.85;
  letter-spacing: -0.06em;

  @media #{$breakpoint-md-up} {
    font-size: font-size($btn-font-size);
  }
}

