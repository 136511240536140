.row {
  padding-left: $page-padding--mobile;
  padding-right: $page-padding--mobile;

  @media #{$breakpoint-md-up} {
    padding-left: $page-padding;
    padding-right: $page-padding;
  }
}

.col-md-offset-0 {
  @media #{$breakpoint-md-up} {
    padding-left: 0;
  }
}

.btn {
  appearance: none;
  outline: 0;
  border: 0;
  display: inline-block;
  padding: 0.4em 2em;
  width: auto;
  transition: transform 0.5s ease;
  background-color: $color-orange;
  text-align: center;
  text-decoration: none;
  border-radius: 2em;
  margin-top: 6rem;
  color: $color-brown;
  text-transform: uppercase;
  @extend .btn-text-style;

  &:hover {
    cursor: pointer;
    background-color: $color-white;
    color: $color-brown;
  }

  @media #{$breakpoint-md-up} {
    padding: 0.4em 2.5em;
    margin-top: 5rem;
  }

  &.btn--big {
    width: 100%;
    @extend .btn-big-text-style;
    padding: 0.6em;
    margin-top: 3rem;

    @media #{$breakpoint-md-up} {
      padding: 0.25em;
      margin-top: 5rem;
    }
  }

  &--violet {
    background-color: $color-violet;
  }

  &--green {
    background-color: $color-green;
  }

  &--blue {
    background-color: $color-blue;
  }
}

.io-trigger {
  width: 100%;
  height: 1px;
  background-color: transparent;
  position: relative;
  margin-bottom: -1px;
}

.float-effect {
  animation-name: animate-float;
  animation-duration: 13s;
  animation-iteration-count: infinite;
  // animation-direction: alternate;
  transform-origin: center;
}

@keyframes animate-float {
  0%   { transform: rotate(10deg)  translate(0rem, 0rem); }
  20%  { transform: rotate(5deg)  translate(0.03rem, -1.7rem); }
  50%   { transform: rotate(1deg)  translate(-0.05rem, -0.1rem); }
  70%  { transform: rotate(-1deg)  translate(-0.05rem, -1.2rem); }
  100%   { transform: rotate(10deg)  translate(0rem, 0rem); }
}



.cookie-policy--open .cookie-policy,
.terms-and-conditions--open .terms-and-conditions {
  transform: translateX(0%);
}


.cookie-policy {
  [data-cookie-policy-open] {
    display: none;
  }

  .CookieDeclarationDialogText {
    display: none;
  }

  table.CookieDeclarationTable * {
    font-size: 0.5rem;
  }
}


.cookie-policy,
.terms-and-conditions {
  width: 95%;
  max-width: 50em;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: $color-white;
  color: $color-brown;
  z-index: 110;
  @extend .terms-text--variant;
  transform: translateX(105%);
  transition: transform 0.3s ease-out;
  box-shadow: -8px 1px 10px #23110022;

  &__content {
    width: 100%;
    height: 100%;
    padding: $page-padding;
    padding-right: calc(#{$page-padding} + 1.5em);
    overflow-y: auto;
    padding-top: 4em;
  }

  &__close {
    position: absolute;
    top: $page-padding/2;
    right: $page-padding;
    width: 1.5em;
    height: 1.5em;

    @media #{$breakpoint-sm-up} {
      top: $page-padding;
      right: $page-padding;
    }

    &:after,
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      display: block;
      background-color: $color-brown;
      transform-origin: 50%;
    }

    &:before {
      transform: rotate(-45deg);
      margin-top: 45%;
    }
    &:after {
      transform: rotate(45deg);
      margin-top: 45%;
    }

    &:hover {
      cursor: pointer;
      &:after,
      &:before {
        background-color: $color-orange;
      }
    }
  }
}
