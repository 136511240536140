/* GRID */
$grid-columns: 40; // Set number of columns in the grid

$xs-max: 640px; // 40rem
$sm-max: 1023px; // 64rem
$md-max: 1439px; // 90rem
$lg-max: 1790px; // $lg-max: 1920px; // 120rem

/* GUTTER AND CONTAINER PAD */
/* gutter and container pad changes on sm */
$gutter-size: 0;
$gutter: 0 $gutter-size; // Set gutter size

$page-padding: 20px;
$page-padding--mobile: 15px;


/* COLORS */
$color-brown: #231100;
$color-violet: #e390f0;
$color-orange: #ff623a;
$color-green: #00a22a;
$color-blue: #547cdc;
$color-green-lime: #e5f856;
$color-white: #f3f0e7;

/* FONT SIZES */
$base-font-size-no-unit--desktop: 22;// px
$base-font-size-vw--desktop: 1.527;// 100vw * 22px / 1440px --> 1.527vw

$base-font-size-no-unit--tablet: 14;// px
$base-font-size-vw--tablet: 2.187;// 100vw * 14px / 640px --> 2.187vw

$base-font-size-no-unit--mobile: 14;// px
$base-font-size-vw--mobile: 3.358;// 100vw * 14px / 390px --> 3.358vw

$base-font-size-no-unit--indesign: 19;// pt
$pt-to-px-ratio: $base-font-size-no-unit--desktop/$base-font-size-no-unit--indesign;

$p-font-size: 19 * $pt-to-px-ratio;// 19pt
$p-variant-font-size: 24 * $pt-to-px-ratio;
$h2-font-size: 130 * $pt-to-px-ratio;
$h3-font-size: 85 * $pt-to-px-ratio;
$h4-font-size: 42 * $pt-to-px-ratio;//60
$h5-font-size: 40 * $pt-to-px-ratio;
$h1-font-size: 30 * $pt-to-px-ratio;
$h6-font-size: 24 * $pt-to-px-ratio;
$footer-font-size: 15 * $pt-to-px-ratio;
$header-font-size: 22 * $pt-to-px-ratio;
$btn-big-font-size: 130 * $pt-to-px-ratio;
$btn-font-size: 60 * $pt-to-px-ratio;
$terms-font-size: 12 * $pt-to-px-ratio;

$p-font-size--mobile: 14;
$p-variant-font-size--mobile: 19;
$h2-font-size--mobile: 90;
$h3-font-size--mobile: 56;
$h4-font-size--mobile: 36;
$h5-font-size--mobile: 30;
$h1-font-size--mobile: 23;
$h6-font-size--mobile: 16;
$footer-font-size--mobile: 14;
$btn-big-font-size--mobile: 56;
$btn-font-size--mobile: 33;
$header-font-size--mobile: 28;
$terms-font-size--mobile: 12;

