.footer {
  margin-top: 9rem;
  margin-bottom: $page-padding--mobile;
  @extend .footer-text-style;

  @media #{$breakpoint-sm-up} {
    margin-top: 10rem;
    margin-bottom: 3.5rem;
  }

  &__link  {
    display: block;
    margin-top: 0.1rem;
  }

  &__social-icons {
    display: flex;
  }

  &__social-icon {
    margin-top: 2em;
    width: 4em;
    padding-right: 0.5em;
    transition: transform 0.5s ease;

    @media #{$breakpoint-md-up} {
      width: 3.8em;
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  &__headline {
    margin-top: 2.6em;

    &, & * {
      font-size: font-size($footer-font-size--mobile*1.25);

      @media #{$breakpoint-sm-up} {
        font-size: font-size($footer-font-size);
      }
    }
  }
}
